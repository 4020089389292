import React from "react";
import "./PoliticsScreen.css";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";
import arrowleft from "../assets/arrow-left.svg";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/storytelling-logo-white.png";

function PrivacyScreen() {

    return (
        <div>
            <div className='politicspromise'>
                <header className="politicspromise__banner"
                    style={{
                        backgroundSize: "cover",
                        backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, #8609a5, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover02.webp?alt=media&token=3760f7a5-3e9a-49d2-87e2-bfde1964e602&_gl=1*1c2i1cf*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc1Ni4yNi4wLjA."
                )`,
                        backgroundPosition: "top center",
                    }}>
                    <div className='politicspromise__content'>
                        <Link to="/" className="politicspromise__back" >
                            <img
                                className='politicspromise__back__img'
                                src={arrowleft}
                                alt=""
                            />
                            <p className='politicspromise__back__msg' >Regresar a la página de inicio</p>
                        </Link>

                        <div style={{ padding: "40px 0" }} >
                            <div>
                                <img
                                    src={logo}
                                    alt=''
                                    style={{ maxWidth: "250px" }}
                                />
                            </div>
                            <h1>Política de Privacidad</h1>
                            <img
                                className='politicspromise__back__img'
                                src={arrowdown}
                                alt=""
                            />
                        </div>
                    </div>
                </header>

                <div className='politicspromise__maintext'>
                    <p>
                        Storytelling SOUNDS LLC , y sus afiliados o cesionarios (“  Storytelling , ”) opera y administra la APP del sitio web accesible en www.Storytelling.app  , incluidos todos sus subdominios (la “ APP -app ”), donde los usuarios y creadores de  Audio Contenido  independientes pueden cargar, publicar, transmitir, compartir, otorgar licencias y vender sus Audios  originales, ofrecer servicios como sesiones de transmisión de audio o chat  de audio o chat  en vivo o bienes tangibles a los usuarios, y donde quienes suben Audios  y los usuarios pueden interactuar (nuestros " Servicios "), y esta política de privacidad (esta " Política de Privacidad ") rige nuestras prácticas de información y describe cómo recopilamos, usamos, compartimos y protegemos la información que usted nos proporciona cuando utiliza nuestros Servicios a través de la APP.
                    </p>

                    <p>Los términos en mayúscula utilizados en esta Política de Privacidad (por ejemplo, "Usuario" o "Creador de Audio   Contenido   de audio") tienen los significados indicados en la Sección 9 a continuación.</p>

                    <p>Cuando crea su cuenta o realiza una compra en la APP, se le pedirá que haga clic en "Acepto" para indicar que acepta los términos de esta Política de Privacidad.</p>

                    <h2>LA APP LIMITA EXPRESA Y ESTRICTAMENTE SU USO A ADULTOS DE AL MENOS 18 AÑOS O QUE HAYAN ALCANZADO LA MAYORÍA DE EDAD EN SU JURISDICCIÓN. TODAS LAS DEMÁS PERSONAS TIENEN ESTRICTAMENTE PROHIBIDO ACCEDER O ESCUCHAR LOS AUDIO CONTENIDO S DE ESTA APP. EL USO DE LA APP ES NULO CUANDO LO PROHÍBA LA LEY.  Storytelling, NO SOLICITA NI RECOPILA A SABIENDAS INFORMACIÓN O DATOS PERSONALES DE PERSONAS QUE NO HAYAN ALCANZADO LA MAYORÍA DE EDAD Y ELIMINA DICHA INFORMACIÓN AL IDENTIFICARLA.</h2>

                    <p>Storytelling, actualizará esta Política de Privacidad cuando cambien sus prácticas de privacidad. Si es un usuario o un Creador de Audio Contenido, la primera vez que inicie sesión en su cuenta después de dicho cambio, se le pedirá que haga clic en "Acepto" para indicar que está de acuerdo con esos cambios.</p>

                    <p>Si no da su consentimiento para la recopilación, el uso y la divulgación de su Información personal como se establece en esta Política de privacidad (incluida cualquier modificación), o no está de acuerdo con su Audio Contenido, no utilice los Servicios y elimine su(s) cuenta(s).</p>

                    <h3>1. QUÉ INFORMACIÓN RECOGEMOS</h3>
                    <p>El término "Información personal" significa información que identifica a cualquier individuo, ya sea directamente (como el nombre o la dirección de correo electrónico) o indirectamente (como la dirección IP o la fecha de nacimiento).</p>
                    <p>Al navegar por la APP, podrás hacerlo como Creador de Audio   Contenido, Usuario o Miembro registrado. Para mayor claridad, todos los Miembros son Usuarios, al igual todos los Usuarios son Miembros.</p>
                    <p>Independientemente de si usted es un Creador de Audio Contenido, un Usuario o un Miembro, Storytelling, en ocasiones puede recopilar Información personal que usted nos proporciona voluntariamente, por ejemplo, cuando se comunica con nosotros, nos informa de cualquier solicitud o nos informa de cualquier problema relacionado con la APP.</p>
                    <h4>Usuarios</h4>
                    <p>Mientras utiliza la APP como Usuario, el sistema operativo de su dispositivo y la identificación única, la dirección IP, los tiempos de acceso, la dirección MAC, la ubicación y el idioma pueden registrarse automáticamente. Esta información puede almacenarse localmente en el dispositivo que esté utilizando y puede comunicarse a Storytelling, en particular para garantizar el uso y funcionamiento adecuados de la APP, pero también con fines estadísticos y para mejorar la APP y sus funciones.</p>
                    <h4>Miembros</h4>
                    <p>Además de lo anterior, cuando utilice la APP por primera vez como Miembro, se le pedirá que proporcione información para crear su cuenta y el perfil vinculado a su cuenta. Esta información puede ser, entre otros, su nombre, fecha de nacimiento, dirección de correo electrónico, así como cualquier otra información necesaria para brindarle los Servicios y que la ley aplicable exige que se trate como "información personal".</p>
                    <p>También recopilamos información sobre nuestras preferencias y gustos personales con respecto al Audio Contenido al que accede y al que le gustaría acceder en la APP, para adaptar su experiencia a sus intereses, así como para saber qué tipo de Audio Contenido prefieren nuestros Miembros.</p>
                    <p>Cada vez que utiliza la APP, recopilamos datos sobre todas sus interacciones con los Servicios y otros Usuarios, incluidos los Usuarios que cargan Audio Contenido en la APP, a través de archivos de registro del servidor. Esta información puede asociarse con su cuenta y perfil, dirección IP o ID de dispositivo con el fin de brindarle los Servicios y mejorar su experiencia.</p>
                    <p>Siempre que participe en una transmisión de audio o chat  en vivo privada o semiprivada en la APP,  Storytelling , puede monitorear y/o grabar sus comunicaciones escritas y las transmisiones en vivo con el fin de garantizar y documentar el cumplimiento de todas las leyes aplicables, así como de los Términos . y Condiciones para Usuarios y Miembros y nuestros Términos de Servicio para Creador de Audio Contenido es . Su participación en una transmisión de audio o chat en vivo se asociará con su cuenta y perfil, dirección IP e ID de dispositivo con el fin de brindarle los Servicios.</p>
                    <p>Cuando realiza una compra, podemos recopilar información sobre dicha compra y su método de pago, y mantenemos información sobre su historial de compras. La información se utiliza principalmente para respaldar su interacción con la APP y para prevenir fraudes.</p>
                    <h4>Creador de Audio Contenido es</h4>
                    <p>Además de lo que se describe anteriormente para Usuarios y Miembros, cuando utilice la APP por primera vez como Creador de Audio Contenido se le pedirá que proporcione lo siguiente:</p>
                    <ul>
                        <li>Una identificación con fotografía emitida por el gobierno, para que podamos verificar su identidad y su edad;
                        </li>
                        <li>
                            Su información bancaria, para que podamos realizar el pago que se le debe según los Términos de servicio para quienes suben archivos ; y
                        </li>
                        <li>
                            Su estatus de ciudadanía para que podamos determinar las reglas tributarias que debemos cumplir en nuestra relación con usted.
                        </li>
                    </ul>

                    <h3>2. POR QUÉ RECOGEMOS ESTA INFORMACIÓN</h3>
                    <p>Utilizamos la información que recopilamos por los siguientes motivos:</p>
                    <ul>
                        <li>Para crear su cuenta y perfil y brindarle los Servicios;
                        </li>

                        <li>
                            Para enviarle información relacionada con los Servicios, incluidas confirmaciones, facturas, avisos técnicos, actualizaciones, alertas de seguridad y mensajes administrativos y de soporte;
                        </li>

                        <li>
                            Para garantizar el correcto funcionamiento de la APP;
                        </li>

                        <li>
                            Para garantizar el cumplimiento de los Términos y condiciones para usuarios y miembros y nuestros Términos de servicio para usuarios que suben archivos ;
                        </li>

                        <li>
                            Abordar cualquier problema que se nos presente;
                        </li>

                        <li>
                            Monitorear y analizar el uso y las tendencias de los Servicios y medir de otro modo la efectividad de los Servicios;
                        </li>

                        <li>
                            Para obtener información sobre cómo los Usuarios, Miembros y Creador de Audio Contenido es interactúan con la APP con el fin de evaluar y mejorar el Audio Contenido y las características de la APP (incluso mediante el uso de análisis de datos o aprendizaje automático), o para desarrollar nuevos productos y servicios;
                        </li>

                        <li>
                            Responder a comentarios y preguntas y brindar atención al cliente;
                        </li>

                        <li>
                            Personalizar su experiencia con la APP;
                        </li>

                        <li>
                            Realizar encuestas, concursos y sorteos;
                        </li>

                        <li>
                            Para mejorar la experiencia y el disfrute al utilizar nuestros Servicios;
                        </li>

                        <li>
                            Para contactarlo sobre su experiencia con la APP y notificarle sobre novedades y promociones de la empresa;
                        </li>

                        <li>
                            Comercializar y promover los Servicios y otros servicios y productos que ofrecemos;
                        </li>

                        <li>
                            Operar los Servicios, brindar atención al cliente y funciones personalizadas y proteger la seguridad de los Servicios;
                        </li>

                        <li>
                            Para proteger nuestros derechos e intereses legales, incluido el cumplimiento de nuestras obligaciones legales (como evitar el fraude o el uso indebido de la APP);
                        </li>

                        <li>
                            Para dar efecto a cualquier otro propósito al que usted pueda dar su consentimiento.
                        </li>

                    </ul>
                    <p>Si dio su consentimiento para que usemos su información para un propósito específico, tiene derecho a cambiar de opinión en cualquier momento, pero esto no afectará ningún procesamiento que ya haya tenido lugar. Cuando utilizamos su información porque nosotros o un tercero tenemos un interés legítimo en hacerlo, usted tiene derecho a oponerse a ese uso, aunque, en algunos casos, esto puede significar que ya no utilice los Servicios.</p>

                    <h3>3. ¿CÓMO SE ALMACENA Y COMUNICA LA INFORMACIÓN?</h3>
                    <p>Storytelling, conserva su Información personal solo durante el tiempo necesario para cumplir con los fines descritos en esta Política de privacidad, o de acuerdo con los requisitos legales.</p>
                    <p>Después del período establecido en ese cronograma, destruiremos su Información personal o la “despersonalizaremos” (lo que significa que eliminaremos toda la información que permita identificarlo específicamente).</p>
                    <p>Nunca divulgamos su información personal a nadie fuera de Storytelling, sin su consentimiento, excepto en los siguientes casos:</p>
                    <ul>
                        <li>Si Storytelling, vende su negocio, puede revelar su información personal en la medida necesaria en relación con una revisión de diligencia debida por parte del posible comprador.  Storytelling, incluirá disposiciones en el contrato de venta para exigir que el comprador trate su Información personal de acuerdo con la ley aplicable.
                        </li>
                        <li>Storytelling, puede divulgar su información personal a autoridades públicas sin su consentimiento si la ley nos lo exige o nos permite hacerlo, incluso para defender nuestros derechos, prevenir fraudes o de otro modo en cumplimiento de la ley.
                        </li>
                    </ul>

                    <h3>4. IDENTIFICACIÓN</h3>
                    <p>Los miembros y los Creador de Audio Contenido es se identifican públicamente por el alias que utilizan a través de la APP, y todo el Audio Contenido que publican (incluidos Audios o chat) es visible para todos los Usuarios y puede verse, recopilarse, copiarse o usarse sin consentimiento. Le recomendamos que no divulgue información sobre usted mientras contribuye con Audio Contenido o utiliza la APP, incluso en salas de chat, blogs, comentarios, reseñas, muros o funciones similares en nuestros Servicios. La información que usted divulga en cualquiera de estos casos es información pública y, como tal, no existe ninguna expectativa de privacidad o confidencialidad hacia ella.</p>
                    <p>Dado que usted controla la información que se muestra o publica en su cuenta o perfil y la información que comparte a través de la APP en general, no somos responsables de la Información personal que elija poner a disposición a través de la APP. En cambio, usted es el único y total responsable de cualquier consecuencia que surja de dicha información mostrada o publicada, incluso si otros pueden identificarlo en función del Audio Contenido que ha publicado.</p>

                    <h3>5. SEGURIDAD</h3>
                    <p>Todos los datos se almacenan en servidores con medidas de seguridad de alta seguridad, protegidos contra el uso indebido y el acceso de personas no autorizadas. Dichas medidas de seguridad incluyen contraseñas, cifrado de datos y áreas de acceso restringido en nuestras instalaciones. Sin embargo, ningún sistema de seguridad es impenetrable y, debido a la naturaleza inherente de Internet, no podemos garantizar que los datos, durante la transmisión de audio o chat a través de Internet o mientras estén almacenados en nuestros sistemas o bajo nuestro cuidado, estén absolutamente a salvo de la intrusión de otros. Le notificaremos sobre cualquier violación de datos según lo exige la ley aplicable.</p>
                    <p>Su uso de la APP puede implicar la transferencia, el almacenamiento y el procesamiento de su información hacia y en varios países del mundo donde se encuentran nuestros servidores y se operan nuestras bases de datos, incluido los Estados Unidos de América, que pueden tener diferentes niveles de protección de la privacidad. que tu país. Al utilizar la APP, usted acepta que su información sea transferida a nuestras instalaciones y a las instalaciones de aquellos terceros con quienes la compartimos como se describe en esta Política de Privacidad.</p>

                    <h3>6. HIPERVÍNCULOS Y SITIOS DE TERCEROS</h3>
                    <p>La APP puede incorporar enlaces a otros sitios o recursos en Internet, como redes sociales de terceros o sitios o aplicaciones de redes sociales. Al hacer clic en dichos enlaces, abandonará la APP. Cualquier información personal que envíe a través de estos sitios está sujeta a su política de privacidad.  Storytelling, no controla el funcionamiento de los sitios web o aplicaciones de terceros y el hecho de que aparezcan en la APP no establece nuestro patrocinio o afiliación con esas personas o empresas ni crea ninguna responsabilidad para Storytelling, Storytelling, no es responsable de las actividades de terceros, personas o empresas, el Contenido de sus sitios, su uso de la información, personal o no, que usted les proporcione, ni de ningún producto o servicio que puedan ofrecer.</p>
                    <p>Cuando realice pagos a través de la APP, será redirigido al sitio de nuestro proveedor de servicios de pago y cualquier Información personal que envíe se enviará a ese proveedor directamente sin nuestra participación. Le recomendamos que lea sus políticas de privacidad, para que pueda comprender la forma en que manejarán su Información personal.</p>

                    <h3>7. COOKIES</h3>
                    <p>Podemos utilizar cookies (pequeños archivos de texto colocados en el disco duro de un dispositivo) o tecnología similar para recopilar información no identificable sobre su computadora o dispositivo móvil y sus actividades con el fin de ayudar a mejorar su experiencia de usuario (por ejemplo, almacenando sus preferencias y configuraciones). y autenticar sus preferencias). Puede administrar la configuración de sus cookies directamente en su navegador o, cuando corresponda, en su dispositivo; sin embargo, tenga en cuenta que, si desactiva o elimina las cookies, es posible que algunas partes de la APP no funcionen correctamente.</p>

                    <h3>8. TUS DERECHOS</h3>
                    <p>Le ofrecemos opciones con respecto a la recopilación, corrección, uso, retención y divulgación de su Información personal y respetaremos las decisiones que tome. Tiene derecho a rechazar la recopilación, el uso, la retención y/o la divulgación de su Información personal comunicándose con el Director de Privacidad por correo electrónico (en soporte@Storytelling.app  ). Sin embargo, tenga en cuenta que, al hacerlo, su acceso a nuestros Servicios podría verse limitado. A continuación, describimos las herramientas y procesos para realizar estas solicitudes.</p>
                    <ul>
                        <li>
                            Acceda y actualice su información: puede acceder y actualizar su información desde el Servicio. Por ejemplo, puede acceder a la información de su perfil desde su cuenta. Puede actualizar la información de su perfil dentro de la configuración de su cuenta y modificar el Contenido que contiene información sobre usted utilizando las herramientas de edición asociadas con ese Audio Contenido.
                        </li>

                        <li>
                            Puede comunicarse con nuestro Director de Privacidad por correo electrónico en soporte@Storytelling.app para obtener acceso o rectificar cualquier información personal que esté bajo nuestro control, o si tiene alguna pregunta o inquietud con respecto a esta Política de Privacidad. Le proporcionaremos acceso dentro de los 15 días posteriores a su solicitud, a menos que se nos prohíba hacerlo (por ejemplo, si brindar acceso comprometería la privacidad de una tercera persona). Si no podemos proporcionar acceso, le explicaremos el motivo.
                        </li>

                        <li>
                            Elimine su cuenta: si ya no desea utilizar nuestros Servicios, puede eliminar su cuenta. Sin embargo, podemos seguir conservando parte de su información personal como se describe en esta Política de privacidad.
                        </li>

                        <li>
                            Optar por no recibir comunicaciones: puede optar por no recibir comunicaciones promocionales nuestras utilizando el enlace para cancelar la suscripción en cada correo electrónico que enviamos, actualizando sus preferencias de correo electrónico dentro del menú de configuración de su cuenta o comunicándose con nosotros como se indica a continuación para eliminar su información de contacto. de nuestra lista de correo electrónico promocional o base de datos de registro. Incluso después de que usted opte por no recibir mensajes promocionales nuestros, continuará recibiendo mensajes transaccionales nuestros con respecto a nuestros Servicios. Puede optar por no recibir algunos mensajes de notificación en la configuración de su cuenta.
                        </li>

                        <li>
                            Desactive los controles de cookies: los controles de cookies basados en el navegador se describen en la sección 7. COOKIES
                        </li>

                        <li>
                            Envíe señales de “No rastrear”: algunos navegadores han incorporado funciones de “No rastrear” (DNT) que pueden enviar señales a los sitios web o app que visita indicando que no desea que lo rastreen. Debido a que aún no existe un entendimiento común sobre cómo interpretar la señal DNT, nuestros Servicios actualmente no responden a las señales DNT del navegador. Puede utilizar la variedad de otras herramientas que proporcionamos para controlar la recopilación y el uso de datos, incluida la posibilidad de optar por no recibir marketing nuestro como se describe anteriormente.
                        </li>

                        <li>
                            Portabilidad de datos: la portabilidad de datos es la capacidad de obtener parte de su información en un formato que puede pasar de un proveedor de servicios a otro. Dependiendo del contexto, esto se aplica a parte de su información, pero no a toda su información. Si lo solicita, le proporcionaremos un archivo electrónico con la información básica de su cuenta y la información que cargó bajo su exclusivo control. Dicha solicitud se procesará dentro de los 30 días siguientes a la fecha en que la recibamos.
                        </li>

                        <li>
                            Derecho a oponerse: puede oponerse a cualquier procesamiento posterior de su información personal comunicándose con nuestro director de privacidad por correo electrónico (en soporte@Storytelling.app  ), en cuyo caso cumpliremos inmediatamente con su solicitud e informaremos a nuestros proveedores de servicios que puedan tener su información personal de su objeción a dicho procesamiento.
                        </li>
                    </ul>
                    <p>En caso de diferencias en las traducciones o interpretaciones, prevalecerá la versión en español de esta Política de Privacidad.</p>
                    <p>Haremos todo lo posible para tratar sus solicitudes con prontitud. Si aún tienes dudas, puedes presentar una queja ante la autoridad de protección de datos de tu país.</p>

                    <h3>9. DEFINICIONES</h3>
                    <p>Cuando se utilicen en esta Política de Privacidad, a menos que haya algo en el contexto o el tema que sea incompatible con el mismo, los siguientes términos tendrán el siguiente significado:</p>
                    <p>“Transmitir” abarcará cargar, publicar, transmitir, compartir y ofrecer Audio Contenido o chat, incluidas transmisiones en vivo;</p>
                    <p>" Audio Contenido " significará, según el contexto en el que se utilice, el Audio Contenido original generado por el CREADOR DE AUDIO CONTENIDO   O CHAT original generado por el Miembro (o ambos). El Audio Contenido incluye, Audios (pregrabados o transmitidos en vivo) y otros materiales, incluidos, entre otros, texto, datos, audio, mensajes (incluido el chat en línea), transmisiones en vivo, comentarios, y concursos., sorteos, y bienes tangibles proporcionados, vendidos, ofrecidos o publicados por los Usuarios o MIEMBROS en la APP de vez en cuando;</p>
                    <p>“Transmisiones en vivo” significará sesiones organizadas por CREADOR DE AUDIO CONTENIDO  durante las cuales el  Audio Contenido  se transmite en vivo en modo privado, semiprivado o público, con o sin chat en vivo, con o sin posibilidad de tener sesiones de cámara bidireccional;</p>
                    <p>“Miembro(s)” significará las personas registradas que utilizan la APP para su uso personal, también llamados "USUARIO (s) de Storytelling,", con inclusión de LOS CREADOR DE AUDIO CONTENIDO. Para mayor claridad, todos los Miembros son Usuarios y todos los Usuarios pueden ser Creadores;</p>
                    <p>“Storytelling,”, así como todos los pronombres en primera persona (como "nosotros", "nos", "nuestro", "nuestro"), se referirán a Storytelling, y/o sus afiliados o cesionarios.  Storytelling, opera y administra la APP, y actúa como intermediario entre LOS CREADOR DE AUDIO   CONTENIDO y el uso de la APP, así como para permitir que los CREADOR DE AUDIO CONTENIDO y los Miembros usuarios interactúen en la APP;</p>
                    <p>APP se referirá al servicio de alojamiento operado y administrado por  Storytelling ,, disponible en  las tiendas Google Play Store (Android) y App Store (iOS) con su web informativa  www.Storytelling.app  (e incluyendo todos sus subdominios, cualquier dominio o URL predecesor o sucesor), donde LOS CREADOR DE AUDIO CONTENIDO  pueden transmitir, licenciar y vender su  Audio Contenido  utilizando las herramientas y funciones proporcionadas por dicha APP, así como también dónde los Usuarios que utilizan la APP pueden ver y publicar  Audio Contenido  (excepto transmisiones en vivo), y dónde LOS CREADOR DE AUDIO   CONTENIDO y los Miembros pueden interactuar en línea;</p>
                    <p>CREADOR DE AUDIO CONTENIDO (ES), así como todos los pronombres de segunda persona (como "usted", "su", "suyo") se referirán a los modelos, artistas y otros productores o estudios de Audio Contenido independientes, registrados como tales con Storytelling, su Audio Contenido original en la APP para uso privado de los Usuarios; y</p>
                    <p>Usuario(s) significará las personas que utilizan la APP para su uso personal, pero que cuando determinen y en el momento que quieran pueden volverse creadores de audio contenido   sin exclusión de LOS CREADOR DE AUDIO CONTENIDO.</p>

                </div>

                <div className="politicspromise__form__copyright">
                    <p>Correo electrónico: soporte@Storytelling.app</p>
                    <p>WEB: www.Storytelling.app</p>
                    <p>Dirección legal: 9274 sw 5th street, Boca Raton, FI, 33428</p>
                    <p>2024 Storytelling Inc. :: All rights reserved.</p>
                </div>
            </div>
            <GoToTop />
        </div>
    )
}

export default PrivacyScreen;
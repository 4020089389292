import React, { useState, useEffect, useRef } from 'react';
import "./AudioPlayer.css";

const audioSamples = [
    {
      "id":1,
      "thumbnailUrl":"https://firebasestorage.googleapis.com/v0/b/cronis-app.appspot.com/o/thumbnail%2FGWmDTHUpllVDOGWzMT6pFA83iwz1%2FGWmDTHUpllVDOGWzMT6pFA83iwz11720733305013?alt=media&token=d35c6030-f27b-401b-996e-0230ad6d2f67",
      "title":"The impact of TikTok on social networks",
      "username":"Storytelling",
      "audioUrl":"https://firebasestorage.googleapis.com/v0/b/cronis-app.appspot.com/o/audio%2FGWmDTHUpllVDOGWzMT6pFA83iwz1%2FGWmDTHUpllVDOGWzMT6pFA83iwz11720733305013?alt=media&token=3ed8d8ff-b2f2-4f1f-8d0b-c020f7689fe3",
      "category":"Revoluciones Digitales",
      "duration":117.96
    },
    {
      "id":2,
      "thumbnailUrl":"https://firebasestorage.googleapis.com/v0/b/cronis-app.appspot.com/o/thumbnail%2FGWmDTHUpllVDOGWzMT6pFA83iwz1%2FGWmDTHUpllVDOGWzMT6pFA83iwz11720732822370?alt=media&token=52478170-1c21-447e-8035-d1a35dc14de3",
      "title":"The creation of Neuralink by Elon Musk",
      "username":"Storytelling",
      "audioUrl":"https://firebasestorage.googleapis.com/v0/b/cronis-app.appspot.com/o/audio%2FGWmDTHUpllVDOGWzMT6pFA83iwz1%2FGWmDTHUpllVDOGWzMT6pFA83iwz11720732822370?alt=media&token=8e2be80f-ead9-466e-80d1-c2b177f96fa6",
      "category":"Revoluciones Digitales",
      "duration":87.168
    },
    {
      "id":3,
      "thumbnailUrl":"https://firebasestorage.googleapis.com/v0/b/cronis-app.appspot.com/o/thumbnail%2FGWmDTHUpllVDOGWzMT6pFA83iwz1%2FGWmDTHUpllVDOGWzMT6pFA83iwz11720298021733?alt=media&token=1596f2e6-ac10-4f3d-8c48-1be1f3e7d48c",
      "title":"The Traditions of Día de Muertos",
      "username":"Storytelling",
      "audioUrl":"https://firebasestorage.googleapis.com/v0/b/cronis-app.appspot.com/o/audio%2FGWmDTHUpllVDOGWzMT6pFA83iwz1%2FGWmDTHUpllVDOGWzMT6pFA83iwz11720298021733?alt=media&token=8c838b6a-0101-444f-9327-835b685f922d",
      "category":"Huellas Culturales",
      "duration":124.056
    }
  ]

function AudioPlayer() {
    const audioPlayerRef = useRef(null);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
      if (audioSamples.length > 0) {
          if (audioPlayerRef.current) {
            audioPlayerRef.current.src = audioSamples[currentTrackIndex].audioUrl;
            if (isPlaying) {
              audioPlayerRef.current.play();
            }
          }
        }
      }, [currentTrackIndex, isPlaying]);
      
      const playNextTrack = () => {
      setCurrentTrackIndex((prevIndex) =>
          prevIndex === audioSamples.length - 1 ? 0 : prevIndex + 1
      );
      setIsPlaying(true);
      };
  
      const playPreviousTrack = () => {
      setCurrentTrackIndex((prevIndex) =>
          prevIndex === 0 ? audioSamples.length - 1 : prevIndex - 1
      );
      setIsPlaying(true);
      };
  
      const handleTrackEnded = () => {
      playNextTrack();
      };
  
      const currentTrack = audioSamples[currentTrackIndex];

    return (
      <div className='audioplayer'>
        <div className='audioplayer__container'>
        <div className="audioplayer__track" >
          <img src={currentTrack?.thumbnailUrl} alt={currentTrack?.title} />
          <div className="audioplayer__track__info" >
            <h3>{currentTrack?.title}</h3>
            <p>{currentTrack?.username}</p>
          </div>
        </div>
        
        <div className="controls" >
            <div className="controls__prev"  onClick={playPreviousTrack}>
                <img src='https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fprevious.png?alt=media&token=52911b26-76bf-4235-a81c-61e87467940b' alt='' title='Audio anterior' />
            </div>
            <div className="controls__next"  onClick={playNextTrack}>
                <img src='https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fnext.png?alt=media&token=6c32bd6c-7394-4dfa-9a3a-ee2a5be3f7c5' alt='' title='Audio siguiente ' />
            </div>
        </div>
        </div>

        <div className="player" >
            <audio ref={audioPlayerRef} autoPlay={isPlaying} controls onEnded={handleTrackEnded} controlsList="nodownload"/>
        </div>

      </div>
    );
  }
  
  export default AudioPlayer;
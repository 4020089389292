import React from "react";
import "./PoliticsScreen.css";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";
import arrowleft from "../assets/arrow-left.svg";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/storytelling-logo-white.png";

function UploadTermsScreen() {

    return (
        <div>
            <div className='politicspromise'>
                <header className="politicspromise__banner"
                    style={{
                        backgroundSize: "cover",
                        backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, #8609a5, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover02.webp?alt=media&token=3760f7a5-3e9a-49d2-87e2-bfde1964e602&_gl=1*1c2i1cf*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc1Ni4yNi4wLjA."
                )`,
                        backgroundPosition: "top center",
                    }}>
                    <div className='politicspromise__content'>
                        <Link to="/" className="politicspromise__back" >
                            <img
                                className='politicspromise__back__img'
                                src={arrowleft}
                                alt=""
                            />
                            <p className='politicspromise__back__msg' >Regresar a la página de inicio</p>
                        </Link>

                        <div style={{ padding: "40px 0" }} >
                            <div>
                                <img
                                    src={logo}
                                    alt=''
                                    style={{ maxWidth: "250px" }}
                                />
                            </div>
                            <h1>Política de Carga de Audios</h1>
                            <img
                                className='politicspromise__back__img'
                                src={arrowdown}
                                alt=""
                            />
                        </div>
                    </div>
                </header>

                <div className='politicspromise__maintext'>
                    <p>
                        Storytelling SOUNDS LLC (en adelante, Storytelling como se define a continuación) opera y administra la app ABRACDABRA y con su web  informativa www.Storytelling.app (como se define a continuación), donde los creadores de audio contenido independientes pueden cargar, publicar, transmitir, otorgar licencias, compartir y vender sus  audio contenido  originales y bienes tangibles. y ofrecer servicios, como transmisiones de audio en vivo o chats en vivo, a los usuarios. La Plataforma APP proporciona entretenimiento, pero por la característica del audio y temas a tratar entra en entretenimiento para adultos (no pornográfico) y solo está abierta a adultos que den su consentimiento
                    </p>

                    <h2>EL CREADOR DE CONTENIDO DE AUDIO CONTENIDO DEBE TENER AL MENOS 18 AÑOS Y LA MAYORÍA DE EDAD Y EL CONSENTIMIENTO LEGAL SEGÚN LAS LEYES DE LA JURISDICCIÓN APLICABLE PARA ACEPTAR ESTOS TÉRMINOS DE SERVICIOS. EL USO DE LA PLATAFORMA APP ES NULO CUANDO LO PROHÍBA LA LEY.</h2>
                    <h2>NO TOLERAMOS NINGÚN MATERIAL QUE INVOLUCRA O REPRESENTA A MENORES, INCLUYENDO CUALQUIER REPRESENTACIÓN VIRTUAL, Y TENEMOS UNA POLÍTICA DE TOLERANCIA CERO CON RESPECTO A PEDOFILES, PEDERASTS O CUALQUIER ACTIVIDAD PEDOFILICA, PEDERASTICA O RELACIONADA SIMILAR. TOMAMOS GRANDES MEDIDAS PARA GARANTIZAR QUE NO APAREZCAN MENORES DE EDAD EN LA PLATAFORMA APP Y DENUNCIAMOS CUALQUIER ACTIVIDAD ILEGAL.</h2>

                    <p>Estos términos de servicios (en lo sucesivo, "TOS"), incluida nuestra  Política de privacidad y todas las reglas de Storytelling , como "Reglas de carga", "Reglas de contenido", "Confianza y seguridad de Storytelling ", "Palabras prohibidas", "Facturación". ", y los "Requisitos de mantenimiento de registros", que se encuentran en (las " Políticas de Storytelling ") forman el contrato vinculante entre Storytelling y CREADOR DE CONTENIDO. Si EL CREADOR DE CONTENIDO no está de acuerdo con todas estas Reglas de la Plataforma APP en este caso APP y la web informativa, que formarán parte integral de los TOS como si se recitan detalladamente en este documento, El creador de contenido no podrá usar la Plataforma APP y no deberá proceder a crear una cuenta ni utilizar la Plataforma APP de otro modo. Al utilizar la Plataforma APP, EL CREADOR DE CONTENIDO demuestra su voluntad y confirma su acuerdo de estar sujeto a todos estos TOS, incluidas todas las modificaciones que se les realicen de vez en cuando. Storytelling se reserva el derecho, a su exclusivo criterio, de revisar estos TOS.  EL CREADOR DE CONTENIDO acepta que todos los cambios a los TOS son efectivos y ejecutables al momento de su publicación. Además, EL CREADOR DE CONTENIDO acepta y comprende que es responsabilidad estar informado y revisar periódicamente todos estos TOS para ver si algo ha cambiado. Storytelling no será responsable por el incumplimiento de los derechos legales por parte d creador de contenido.</p>

                    <h2>SI EL CREADOR DE CONTENIDO NO ESTÁ DE ACUERDO CON ESTOS TOS, O SI EL CREADOR DE CONTENIDO NO ESTÁ DE ACUERDO CON CUALQUIER TOS REVISADO, EL CREADOR DE CONTENIDO NO DEBE UTILIZAR LA PLATAFORMA APP.</h2>

                    <h3>1. DEFINICIONES</h3>
                    <p>Cuando se utilicen en estos TOS, a menos que haya algo en el contexto o el tema incompatible con los mismos, los siguientes términos tendrán el siguiente significado:</p>
                    <p>1.1 "Contenido" significará, dependiendo del contexto en el que se utilice, el contenido original generado por El creador de contenido O el contenido original generado por el Usuario (o ambos). El contenido incluye, audio contenido (pregrabados o transmitidos en vivo) y otros materiales, incluidos, texto, datos, audio, mensajes (incluido el chat en línea), transmisiones en vivo, comentarios,  concursos, sorteos, y bienes tangibles proporcionados, vendidos, ofrecidos o publicados por CREADORES DE CONTENIDO o Usuarios en la Plataforma APP de vez en cuando;</p>
                    <p>1.2 "Transmisiones en vivo" significará sesiones organizadas por LOS CREADORES DE CONTENIDO durante las cuales el Contenido se transmite en vivo AUDIO en modo privado, semiprivado o público, con o sin chat en vivo, con o sin posibilidad de tener sesiones de cámara bidireccional;</p>
                    <p>1.3 "Usuario (s)" significará las personas registradas que utilizan la Plataforma APP para su uso personal, también llamados "Usuario (s) de Storytelling ", con exclusión de LOS CREADORES DE CONTENIDO. Para mayor claridad, todos los Usuarios son creadores   todos los creadores son Usuario;</p>
                    <p>1.4 "Storytelling ", así como todos los pronombres en primera persona (como “nosotros”, “nos”, “nuestro”, “nuestro”), se referirá a Storytelling SOUNDS LLC y/o sus afiliados o cesionarios. Storytelling opera y administra la Plataforma APP y actúa como intermediario entre CREADORES DE CONTENIDO y los Usuarios que utilizan la Plataforma APP y permite a CREADORES DE CONTENIDO y Usuarios interactuar en la Plataforma APP;</p>
                    <p>1.5 "Ingresos netos" significará la cantidad de dinero cobrada a un Usuario determinado por acceder al Contenido, menos cualquier reembolso, contra cargo u otro reembolso similar realizado sobre dicho Contenido licenciado o vendido, así como cualquier pérdida debida a actividad fraudulenta;</p>
                    <p>1.6 "APP" se referirá al servicio de alojamiento operado y administrado por Storytelling , disponible en las tiendas Google Play Store (Android) y App Store (iOS) y su web informativa www.Storytelling.app (e incluyendo todos sus subdominios y cualquier dominio o URL predecesor o sucesor), donde CREADORES DE CONTENIDO pueden cargar, licenciar y vender sus Contenido que utiliza las herramientas y funciones proporcionadas por dicha Plataforma APP, donde los Usuarios que usan la Plataforma APP  pueden escuchar  y publicar Contenido (excepto ciertos tipos de Contenido), y donde CREADORES DE CONTENIDO y los Usuarios pueden interactuar en línea;</p>
                    <p>1.7 " TDS " significa, colectivamente, estos Términos de servicio, nuestra  Política de privacidad , así como todas  las Reglas de la Plataforma APP   y todos los instrumentos y garantías para modificarlos o confirmarlos. “Por el presente”, “en el presente”, “por el presente”, “en virtud del presente” y expresiones similares significan y se refieren a estos TOS;</p>
                    <p>1.8 "Cargar" abarcará cargar, publicar, transmitir, compartir y ofrecer Contenido, incluidas las transmisiones de audio y chat en vivo;</p>
                    <p>1.9” CREADORES DE CONTENIDO”, así como todos los pronombres de segunda persona, se referirán a los modelos, artistas y otros productores o estudios de Audio Contenido independientes, registrados como tales con Storytelling y que cargan su Contenido original en la Plataforma APP app para uso privado de Los usuarios; y</p>
                    <p>1.10 " Usuario(s) " significará las personas que utilizan la Plataforma APP app para su uso personal, con exclusión de LOS CREADORES DE CONTENIDO.</p>

                    <h3>2. TÉRMINOS GENERALES</h3>
                    <p>2.1 Estos TOS rigen los derechos y responsabilidades del creador de contenido en relación con la Plataforma APP y son un contrato vinculante entre El creador de contenido y nosotros (y no entre El creador de contenido y cualquiera de los Usuarios o entre nosotros y cualquiera de los Usuarios). Alentamos al “CREADORES DE CONTENIDO” a leerlos detenida y periódicamente.</p>
                    <p>2.2 Sujeto a la Sección 4.2, y sujeto al cumplimiento por parte DEL CONTENIDO” de contenido de todos estos TOS, Storytelling por la presente otorga al “CREADORES DE CONTENIDO” una licencia gratuita, no exclusiva, intransferible, personal y revocable (la "Licencia") para usar la Plataforma APP únicamente para fines personales. Esta es una Licencia para usar y acceder a la Plataforma APP para el propósito previsto y no es una transferencia de título.</p>
                    <p>2.3 La Plataforma APP brinda al “CREADORES DE CONTENIDO” la capacidad de cargar, licenciar y vender Contenido (incluidos audios contenidos, y suscripciones), así como interactuar en línea con Usuario y ofrecer transmisiones en vivo. Todo el Contenido accesible o vendido a través de la Plataforma APP debe ser accedido o vendido a los Usuarios únicamente para uso privado. UPLOADER entiende y acepta que, como proveedor de servicios, Storytelling solo actúa como intermediario y no será responsable de las actividades realizadas por UPLOADER a través de la Plataforma APP. Storytelling no respalda ningún Contenido o envío y renuncia expresamente a toda responsabilidad en relación con el Contenido o los envíos.  EL CREADOR DE CONTENIDO debe utilizar la Plataforma APP de acuerdo con las leyes locales y los estándares comunitarios; No se puede acceder ni utilizar la Plataforma APP donde lo prohíba la ley.</p>
                    <p>2.4 EL CREADOR DE CONTENIDO acepta y comprende que Storytelling no puede garantizar la seguridad o privacidad de la información proporcionada a través de Internet y la Plataforma APP. Condenamos enérgicamente cualquier forma de interacción entre Usuarios y LOS CREADORES DE CONTENIDO fuera de la Plataforma APP. Cualquier información que EL CREADOR DE CONTENIDO decida compartir es responsabilidad exclusiva DEL CONTENIDO “de contenido y se realiza bajo su propio riesgo. Está prohibido el uso de la Plataforma APP para actividades ilícitas.  EL CREADOR DE CONTENIDO se compromete a tomar precauciones al interactuar en línea con los Usuarios y otros LOS CREADORES DE CONTENIDO.  EL CREADOR DE CONTENIDO también entiende que no realizamos ninguna verificación previa o verificación de antecedentes penales de nuestros Usuarios o LOS CREADORES DE CONTENIDO, y no hacemos ninguna declaración sobre su comportamiento. Por ello, EL CREADOR DE CONTENIDO se compromete a permanecer atento al interactuar en la Plataforma APP.</p>
                    <p>2.5 EL CREADOR DE CONTENIDO acepta y declara cargar únicamente Contenido que sea original y que El creador de contenido sea propietario o esté autorizado a cargar, y para el cual El creador de contenido posee derechos exclusivos de distribución y venta. El contenido bajo licencias no exclusivas de terceros, incluso si otorga derechos de monetización, está estrictamente prohibido en Storytelling. Esta política garantiza la integridad del contenido y evita posibles disputas de derechos. El incumplimiento de esta política puede dar lugar a acciones inmediatas, incluida la eliminación de contenido o la suspensión de la cuenta. Verifique siempre los términos y derechos de la licencia antes de cargar. Además, cualquier Contenido subido a la Plataforma APP debe cumplir con las Reglas de carga de Storytelling. Nos reservamos el derecho de eliminar el Contenido subido que no cumpla con las normas en cualquier momento y sin previo aviso.</p>
                    <p>2.6 Para estos TOS, un “CREADORES DE CONTENIDO” es puramente un contratista independiente. En ningún momento El creador de contenido debe ser visto como un empleado, agente o socio de Storytelling. UPLOADER reconoce que nada en estos TOS implica agencia, empleo, empresa conjunta o asociación entre UPLOADER y Storytelling. Además, UPLOADER reconoce que Storytelling no controla directa o indirectamente el monitoreo, aprobación o revisión del Contenido subido por UPLOADER. UPLOADER entiende que Storytelling puede monitorear o grabar transmisiones de audio en vivo según su Política de privacidad. Revise nuestra Política de Privacidad.</p>

                    <h3>3. CUENTA DE “CREADORES DE CONTENIDO”</h3>
                    <p>3.1 Registro. Para acceder a la Plataforma APP como “CREADORES DE CONTENIDO” y cargar, licenciar y vender el Contenido del creador de contenido, El creador de contenido primero debe registrarse creando una cuenta.  EL CREADOR DE CONTENIDO tendrá que elegir un nombre de usuario, que debe ser único, no ofensivo para otros y que no viole la propiedad intelectual de terceros. Luego, se le pedirá al “CREADORES DE CONTENIDO” que complete el registro completando un formulario de registro en línea que incluye el nombre legal y la fecha de nacimiento d creador de contenido. También se le pedirá al “CREADORES DE CONTENIDO” que proporcione tarjetas de identificación emitidas por el gobierno. Una vez que Storytelling haya recibido el formulario de registro debidamente cumplimentado, Storytelling se reserva el derecho de contactar con EL CREADOR DE CONTENIDO para su validación y/o para obtener información adicional. Storytelling se reserva el derecho de rechazar cualquier registro de cuenta por cualquier motivo.</p>
                    <p>3.2 Confidencialidad de la Cuenta d creador de contenido.   EL CREADOR DE CONTENIDO acepta y declara no compartir la cuenta o la información de inicio de sesión, permitir que nadie más acceda a la cuenta ni hacer nada que pueda poner en peligro la seguridad de la cuenta. Si El creador de contenido tiene conocimiento o sospecha de alguna violación de seguridad, El creador de contenido debe notificar inmediatamente a Storytelling y modificar la información de inicio de sesión. UPLOADER es el único responsable de mantener la confidencialidad de la información de inicio de sesión y será responsable de todos los usos, incluidas las Cargas. Storytelling no es responsable de ningún uso no autorizado de la cuenta. En resumen, UPLOADER es responsable de todo lo que suceda a través de la cuenta.</p>
                    <p>3.3 Responsabilidad y restricciones del contenido</p>
                    <p>3.3.1 Responsabilidad creador de contenido:   EL CREADOR DE CONTENIDO es el único responsable de todo el Contenido que carga, asegurando el cumplimiento de todas las pautas y asumiendo los riesgos asociados con el uso de la Plataforma APP.</p>
                    <p>3.3.2 Restricciones de contenido:   EL CREADOR DE CONTENIDO se compromete a no cargar ningún contenido que sea ilegal, represente a menores o esté prohibido por Storytelling. Todo el contenido debe cumplir con las reglas de carga y las pautas de confianza y seguridad de Storytelling. Además, el contenido no debe infringir derechos de propiedad intelectual de terceros ni la privacidad individual. Esto se extiende a la música o audio contenido de fondo con derechos de autor, que deben estar libres de regalías o haber pagado las regalías correspondientes.  EL CREADOR DE CONTENIDO debe obtener y garantizar el consentimiento expreso de cualquier persona representada en el contenido que carga.</p>
                    <p>3.3.2.1 Actividades prohibidas:   EL CREADOR DE CONTENIDO se compromete a no:</p>
                    <ul>
                        <li>
                            (i) Inhibir las ventas o actividad de otro “CREADORES DE CONTENIDO” en la Plataforma APP.
                        </li>
                        <li>
                            (ii) Participar en cualquier acto que Storytelling considere contrario al espíritu o intención de la Plataforma APP.
                        </li>
                        <li>
                            (iii) Hacer mal uso de la Plataforma APP de cualquier forma.
                        </li>
                        <li>
                            (iv) Usar la Plataforma APP para acoso, prostitución, tráfico sexual o cualquier comportamiento ilegal/ofensivo; o para publicar/compartir declaraciones difamatorias o calumniosas.
                        </li>
                        <li>
                            (v) Intentar el acceso no autorizado a la Plataforma APP, incluido eludir o intentar eludir cualquier tecnología o seguridad de la Plataforma APP.
                        </li>
                        <li>
                            (vi) Interrumpir o interferir con la Plataforma APP o nuestros servidores; o ayudar en dicha interrupción.
                        </li>
                        <li>
                            (vii) Modificar o piratear cualquier aplicación de software o herramienta utilizada por la Plataforma APP.
                        </li>
                        <li>
                            (viii) Vender o promocionar artículos prohibidos en la Plataforma APP (consulte la Sección 6.14).
                        </li>
                        <li>
                            (ix) Violar cualquier ley o reglamento o fomentar o permitir dicha violación.
                        </li>
                    </ul>

                    <p>3.3.3 Visibilidad y capacidad de búsqueda:  el perfil DEL CONTENIDO y el contenido subido se pueden buscar dentro de la Plataforma APP y mediante motores de búsqueda o archivos de terceros. Si EL CREADOR DE CONTENIDO desea eliminar cualquier contenido de estas bases de datos públicas, es su responsabilidad iniciar y gestionar dichas solicitudes, si así lo requiere.</p>

                    <p>3.3.4 Acceso compartido y renuncia:  si El creador de contenido comparte su cuenta o credenciales de inicio de sesión con otro “CREADORES DE CONTENIDO” en virtud de un acuerdo separado, renuncia a cualquier reclamo o daño que surja de ese intercambio. UPLOADER se compromete a defender, indemnizar y eximir a Storytelling de cualquier reclamación o daño resultante.  EL CREADOR DE CONTENIDO asume toda la responsabilidad por las actividades en su cuenta y cualquier instrucción de pago dada a Storytelling en virtud de dichos acuerdos. Si El creador de contenido ordena a Storytelling que realice pagos a otra parte, no puede responsabilizar a Storytelling una vez que se haya realizado el pago.  EL CREADOR DE CONTENIDO debe notificar a Storytelling por escrito si dicho acuerdo termina.</p>
                    <p>3.3.5 Limitación de la fecha de producción del contenido:  Storytelling no aceptará contenido producido antes del 3 de julio de 1995, debido a la ausencia de verificación de edad estandarizada y documentación de consentimiento antes de esta fecha. El envío de contenido anterior a esta fecha infringe estos términos y puede dar lugar a acciones que incluyen la eliminación del contenido, la suspensión o la cancelación de la cuenta. UPLOADER es responsable de garantizar que las fechas de producción del contenido cumplan con esta regla y, si hay incertidumbre, es mejor abstenerse de subirlo.</p>
                    <p>3.4 Storytelling en vivo: carga de transmisión de audio en vivo. Al utilizar la función Storytelling, UPLOADER solo cargará sesiones de transmisión en vivo.</p>
                    <p>3.5 Licencia para su Contenido. Al utilizar la Plataforma APP, UPLOADER conserva el control y los derechos de propiedad legítimos sobre el Contenido de UPLOADER. A los usuarios no se les concede nada más que el derecho de ver, licenciar o comprar el Contenido de creador de contenido. UPLOADER acepta que Storytelling puede utilizar el Contenido de UPLOADER (solo o combinado con otros trabajos) únicamente con fines promocionales o comerciales de acuerdo con estos TOS. En consecuencia, UPLOADER otorga a Storytelling una licencia perpetua, ilimitada, libre de regalías, global, no exclusiva, irrevocable y transferible para explotar el Contenido de UPLOADER por cualquier medio tecnológico.  EL CREADOR DE CONTENIDO renuncia a cualquier “derecho moral” o derechos relacionados en el Contenido para beneficio de Storytelling. UPLOADER representa tener el derecho de conferir esta licencia y renuncias.</p>
                    <p>3.6 Foto de cuenta.  EL CREADOR DE CONTENIDO debe asegurarse de que la foto del perfil de su cuenta sea públicamente aceptable y no muestre contenido explícito. Storytelling se reserva el derecho de eliminar cualquier imagen inapropiada. Las infracciones repetidas pueden dar lugar a la cancelación de la cuenta según estos TOS.</p>
                    <p>3.7 Vincular su cuenta a las redes sociales. La Plataforma APP puede permitir que EL CREADOR DE CONTENIDO se vincule a Plataforma APP de redes sociales de terceros. Al hacerlo, UPLOADER reconoce todos los riesgos potenciales y comprende la falta de control de Storytelling sobre estas Plataforma APP. UPLOADER es responsable de cumplir con los términos de estos Sitios de terceros.</p>
                    <p>3.8 Contenido y cierre de su cuenta:  Si EL CREADOR DE CONTENIDO decide cerrar su cuenta, todo el Contenido asociado se elimina, con las excepciones que se describen a continuación. El contenido eliminado por UPLOADER sigue siendo accesible para los usuarios que lo compraron. Storytelling puede conservar las cuentas desactivadas con fines analíticos y legales, según la Política de Privacidad. Para una eliminación completa, El creador de contenido debe comunicarse con soporte@Storytelling.app</p>
                    <p>3.9 Reglamento 2257. regulaciones están destinadas a combatir la explotación sexual infantil El UPLOADER debe proporcionar documentación que cumpla con los Requisitos de mantenimiento de registros 18 USC § 2257 cuando se le solicite. Aunque Storytelling no es un "productor" según las Regulaciones 2257, El creador de contenido tiene el mandato de mantener y presentar los registros requeridos, manteniéndolos durante la duración de estos TOS más siete (7) años o según lo dicte la ley. No proporcionar la documentación solicitada puede resultar en la cancelación de la cuenta.</p>
                    <p>3.10 Denuncia de pornografía que involucre a usuarios y menores.  Storytelling No tendrá en su app ningún tipo de pornografía ni sexo explícito, sin embargo, se dedica a cooperar con las autoridades en materia de pornografía de todo tipo especialmente infantil. Los informes de actividades ilegales relacionadas con menores deben dirigirse a Storytelling y a las autoridades pertinentes junto con pruebas adjuntas.</p>
                    <p>3.11 Aviso de infracción de derechos de autor. Storytelling respeta los derechos de propiedad intelectual y actuará sobre los avisos de infracción válidos de acuerdo con la Política y el procedimiento de notificación y eliminación de la DMCA.</p>
                    <p>3.12 Emisión de avisos. Al cargar Contenido, EL CREADOR DE CONTENIDO autoriza a Storytelling a actuar contra las infracciones de derechos de autor relacionadas con el Contenido d creador de contenido. Storytelling tiene la discreción de actuar, pero no está obligado. UPLOADER se compromete a indemnizar a Storytelling contra reclamaciones derivadas de esta autoridad.</p>
                    <p>3.13 Agencia de Litigios.  A pesar de las Secciones 2.2 y 3.5, UPLOADER otorga a Storytelling el derecho de perseguir a los infractores de derechos de autor en nombre de UPLOADER, sin limitarse al contenido extraído de la Plataforma APP de Storytelling.</p>
                    <p>3.14 Sección 230 de la Ley de Decencia en las Comunicaciones – 47 USC § 230 Aviso. la Ley de Decencia en las Comunicaciones Usted comprende y reconoce su responsabilidad de evitar que los menores bajo su cuidado accedan a material auditivo no acto, dañino o inapropiado; entiendes que ningún menor puede tener acceso al audio contenido ni chat y aceptas y garantizas tomar medidas responsables para evitar que lo hagan. Las protecciones de control parental (como hardware, software o servicios de filtrado) están disponibles comercialmente y pueden ayudarle a limitar el acceso a material que sea explícito, dañino o inapropiado para menores. Por la presente se le informa que puede buscar dichos servicios en sitios web tales como, entre otros:
                        http://www.safesurfingkids.com/ ,
                        http://www.safekids.com/internet-safety-organizations-sites/ y  http://www.safesearchkids.com/internet-safety-tips-for-kids/#.WR3-e2ffMbo .
                    </p>
                    <p>Storytelling no representa ni respalda ningún producto o información que se encuentre en estos sitios web de terceros y recomienda que usted lleve a cabo su propia diligencia debida antes de instalar cualquier filtro en línea. Además, usted acepta que es su exclusiva responsabilidad, con total exclusión de Storytelling, evitar que menores de edad muestren, escuchen o accedan a cualquier material no apto, dañino o inapropiado.</p>
                    <p>3.16 LOS CREADORES DE CONTENIDO con base en Japón. CREADORES DE CONTENIDO que residan en Japón o tengan ciudadanía japonesa deben garantizar el cumplimiento de todas las leyes japonesas pertinentes, en particular, incluidas las normas de censura. Si bien Storytelling proporciona una Plataforma APP para compartir audio contenido, se reserva el derecho de revisar y eliminar cualquier contenido realizado por LOS CREADORES DE CONTENIDO con sede en Japón que contravenga las leyes japonesas, incluso si dicho contenido se alinea con las Reglas de la Plataforma APP de Storytelling.</p>

                    <h3>4. TARIFAS DE SERVICIO; PRECIOS; PAGOS; CONCURSOS</h3>
                    <p>4.1 Precios de Contenidos. A menos que las funcionalidades de la Plataforma APP dispongan lo contrario, EL CREADOR DE CONTENIDO tiene entera discreción con respecto al precio del Contenido. Sin embargo, tanto el precio mínimo como el máximo deben cumplir con la política de precios vigente de Storytelling, disponible en documento políticas de tarifas. De acuerdo con las funcionalidades de la Plataforma APP, EL CREADOR DE CONTENIDO no puede modificar los precios del Contenido, siempre de acuerdo con la política de precios de Storytelling, y eliminar cualquier Contenido de su propiedad en cualquier momento, basándose únicamente en el criterio d creador de contenido.</p>
                    <p>4.2 Tarifas de servicio. UPLOADER no incurre en cargos por unirse a Storytelling. La compensación de Storytelling por la utilización de la Plataforma APP por parte del CREADOR CONTENIDO “de contenido está determinada por los Ingresos Netos acumulados por el Audio Contenido vendido a los Usuarios. Durante la vigencia de estos TOS, Storytelling deducirá sus tarifas de servicio de los Ingresos Netos generados por las ventas de Contenido a los Usuarios a través de la Plataforma APP. Esto es consistente con la política de precios existente de Storytelling, Las tarifas de servicio mencionadas cubren los impuestos pertinentes, y la factura correspondiente se envía al UPLOADER junto con el pago bimestral del UPLOADER (si corresponde), como se describe en la Sección 4.6 a continuación.</p>
                    <p>4.3 Fichas. Los tokens, por ahora no están disponibles para los usuarios, pero a mediano plazo serán utilizados como único objetivo ser un gesto de gratitud presentado al “CREADORES DE CONTENIDO” en relación con el contenido de transmisión audio en vivo (“Tokens”). Para aclarar, los Tokens no podrán sustituir el pago de Contenido ni ninguna actividad que viole estos TOS, ni son obligatorios para que los Usuarios accedan a la Plataforma APP o al Contenido. Cuando los Usuarios asignan Tokens, lo harán voluntariamente sin esperar reciprocidad alguna. Storytelling retendrá un porcentaje específico de los Tokens obtenidos por creador de contenido, pueden sufrir modificaciones en cualquier momento cuando se ponga a disposición esta iniciativa.</p>
                    <p>4.4 Suscripciones.  Storytelling tendrá acceso a la app completamente gratis en sus inicios, cuando la administración decida cobrar algún tipo de suscripción EL CREADOR DE CONTENIDO reconoce que las modificaciones a las tarifas de suscripción no influirán en los Usuarios actualmente suscritos; a dichos Usuarios se le facturará constantemente a la tarifa inicial hasta que dicho Usuario interrumpa ese servicio de Suscripción. Siempre que un Usuario compre una Suscripción utilizando un código de promoción o a una tarifa reducida, la nueva facturación permanecerá a esa tarifa particular, a menos que ese Usuario cancele ese servicio de Suscripción.</p>
                    <p>4.5 Opciones de pago. Storytelling presenta a UPLOADER una amplia gama de opciones de pago.</p>
                    <p>4.6 Calendario de pagos:  Cada mes se divide en dos períodos de remesas: el primer período abarca del día 1 al 15, mientras que el segundo cubre del día 16 hasta el final del mes. Los días comienzan a las 00:00 a. m., hora universal coordinada (UTC) y concluyen a las 11:59 p. m. UTC. Storytelling remitirá el pago al “CREADORES DE CONTENIDO” dentro de los siete días siguientes a cada período, pero a más tardar veintiocho días, suponiendo que el saldo supere el mínimo requerido para el método de pago seleccionado. Si el saldo no alcanza este mínimo, se trasladará al siguiente período. Independientemente del método de pago elegido, el pago mínimo se establece constantemente en cincuenta ($50,00) dólares, ya sea facilitado a través de la Cámara de Compensación Automatizada (ACH) o cualquier otro método de pago o pasarela utilizada según lo determine Storytelling. Tenga en cuenta que estos mínimos están sujetos a cambios.</p>
                    <p>4.7 Información creador de contenido:  Es responsabilidad del CREADOR CONTENIDO” de garantizar que toda la información de pago sea completa y precisa para evitar cualquier interrupción en el procedimiento de pago y notificar a Storytelling sobre cualquier posible problema de pago. UPLOADER tiene la tarea de mantener la exactitud de toda la información.</p>
                    <p>4.8 Impuestos:   EL CREADOR DE CONTENIDO tiene la obligación exclusiva de presentar los impuestos sobre las ventas pertinentes a las autoridades fiscales respectivas. Pese a lo anterior, Storytelling, si así lo exige la ley, podría retener los impuestos sobre las ventas y remitirlos directamente a las entidades tributarias pertinentes. En tales casos, no se desembolsarán impuestos sobre las ventas al “CREADORES DE CONTENIDO”. Independientemente de cualquier otra disposición en estos TOS, EL CREADOR DE CONTENIDO se compromete por la presente a absolver y defender a Storytelling, sus asociados y sus representantes de cualquier reclamo de terceros, incluidas las agencias tributarias, en relación con cualquier impuesto sobre las ventas o cualquier retención de los mismos, relacionado con el Contenido de creador de contenido. adquirido o licenciado a los Usuarios. Sin perjuicio de cualquier otra disposición en estos TOS, EL CREADOR DE CONTENIDO reconoce que es la única parte responsable de liquidar todos los impuestos, imposiciones o cualquier otro cargo similar gubernamental, estatal y local.</p>
                    <p>4.9 Moneda:  Cada suma mencionada en este documento y todas las transacciones monetarias en la Plataforma APP se presentan en dólares estadounidenses (USD). Cuando sea factible, y exclusivamente a discreción de Storytelling, los desembolsos podrán ejecutarse en la moneda designada por EL CREADOR DE CONTENIDO o en la moneda nativa del domicilio d creador de contenido. De ser así, cualquier conversión de moneda dependerá del tipo de cambio vigente en ese momento.</p>
                    <p>4.10 Ajustes:  Storytelling se abstiene de otorgar créditos, reembolsos o devoluciones en efectivo sobre el Contenido asegurado por los Usuarios. Sin embargo, Storytelling se reserva el derecho, únicamente a su discreción, de modificar o restar la suma prevista o ya dispensada para el pago al “CREADORES DE CONTENIDO” por cualquier motivo, sin previo aviso. Si el pago hubiera sido enviado previamente al “CREADORES DE CONTENIDO”, Storytelling deducirá dichas modificaciones del importe posterior destinado al “CREADORES DE CONTENIDO”. Estas modificaciones pueden atribuirse a causas tales como créditos de usuario, reembolsos, actividades engañosas, Contenido contaminado, Contenido gravemente engañoso o mal etiquetado, o contratiempos tecnológicos.</p>
                    <p>4.11 Ajustes de pago:  en caso de una discrepancia en el envío, Storytelling se reserva el derecho de modificar el pago para corregir el problema. Además, es posible que se aplique una tarifa administrativa para tareas como el reenvío de pagos.  EL CREADOR DE CONTENIDO acepta que Storytelling, a su exclusivo criterio, puede compensar dichos honorarios con cualquier monto adeudado al “CREADORES DE CONTENIDO”.</p>
                    <p>4.12 Responsabilidad de la billetera electrónica:  Si El creador de contenido opta por obtener ganancias a través de una billetera electrónica, El creador de contenido reconoce que la responsabilidad del pago pasa al procesador de pagos de la billetera electrónica una vez que Storytelling envía los fondos. Es deber DEL CONTENIDO “de contenido asegurar los fondos de dicho procesador de pagos de billetera electrónica. Si El creador de contenido encuentra complicaciones para acceder a los fondos, es imperativo que El creador de contenido interactúe directamente con el procesador de pagos. Storytelling se abstendrá de cualquier intervención o responsabilidad sobre los fondos. (NO APLICABLE POR AHORA)</p>
                    <p>4.13 Inactividad de la cuenta: en circunstancias en las que la cuenta del CREADOR DE CONTENIDO” de demuestra inactividad sin detalles de pago legítimos (una pausa de doce (12) meses sucesivos junto con una ausencia de detalles de pago válidos), Storytelling conserva la prerrogativa de detener todas y cada una de las ganancias atribuidas al “CREADORES DE CONTENIDO”. Para buscar una resolución amistosa, El creador de contenido debe iniciar comunicación con Storytelling a través de soporte@Storytelling.red , garantizando la revalidación de la identidad y/o el método de pago adecuado.</p>
                    <p>4.14 Pago – Cierre de la cuenta creador de contenido:  En caso de que El creador de contenido opte por cerrar la cuenta (una decisión que El creador de contenido es libre de tomar en cualquier momento), Storytelling equilibrará los montos adeudados entre ambas partes. Los tokens dentro de la cuenta DEL CONTENIDO” de contenido se convertirán a USD según los paquetes y precios de tokens designados por Storytelling, como se describe en la Sección 4.3. De acuerdo con la Sección 4.6, Storytelling se compromete a desembolsar cualquier saldo residual de la cuenta durante el próximo ciclo de remesas, sujeto a los siguientes términos: CUANDO ESTA FUNCION   ESTÉ DISPONIBLES EN ABRACDABRA.</p>
                    <p>Umbral mínimo de pago:  Storytelling no procesará pagos de saldos inferiores a veinte ($30,00) dólares después de la compensación. Al cerrar una cuenta, El creador de contenido pierde explícita e irrevocablemente cualquier saldo por debajo de este umbral. Para mayor claridad, los saldos por debajo de este umbral no se reactivarán ni transferirán, incluso si El creador de contenido vuelve a abrir una cuenta anterior o crea una nueva.</p>
                    <p>Pago de saldos superiores al umbral:  si un saldo supera los veinte ($30,00) dólares después de la compensación, Storytelling facilitará el pago utilizando la información de pago más reciente registrada. Corresponde al “CREADORES DE CONTENIDO” asegurarse de que Storytelling tenga todos los detalles necesarios para una transferencia exitosa. Si los fondos se devuelven por no poder entregarse, Storytelling retendrá el saldo durante tres años después del cierre de la cuenta, a menos que la ley exija lo contrario. Después de DOS años, si no es reclamado, se considera que El creador de contenido ha renunciado voluntaria y permanentemente a cualquier derecho sobre ese saldo.</p>
                    <p>4.15 Concursos. Aunque los concursos pueden ser accesibles universalmente a través de Storytelling o la Plataforma APP, vale la pena señalar que no todas las características, productos o servicios elaborados, citados, ofrecidos o introducidos a través de los concursos de Storytelling son accesibles para todos los individuos, en todos los límites geográficos, o aptos. u obtenerse para su uso universal. Si el UPLOADER decide participar en un concurso de Storytelling, es una decisión que se toma de forma independiente y el UPLOADER tiene la obligación exclusiva de cumplir con las regulaciones locales pertinentes. Cualquier propuesta relacionada con un concurso de Storytelling se declara nula y sin efecto siempre que se considere ilegal. Las inscripciones canalizadas a concursos de Storytelling pueden someterse a investigación y evaluación y deben alinearse con las Reglas de contenido de Storytelling. Storytelling se reserva el derecho inequívoco de rechazar o descartar la participación DEL CONTENIDO “de contenido en dicho concurso de Storytelling si, tras la evaluación, Storytelling detecta cualquier incumplimiento, evasión o incumplimiento de cualquier directiva del concurso de Storytelling o regla de la Plataforma APP.</p>

                    <h3>5. RESPONSABILIDADES DE Storytelling; DESCARGOS DE RESPONSABILIDAD E INDEMNIZACIÓN</h3>
                    <p>5.1 EL CREADOR DE CONTENIDO reconoce que la Plataforma APP sigue siendo una tecnología y un concepto en continua evolución. Siempre que Storytelling ofrezca acceso “CREADORES DE CONTENIDO” a la Plataforma APP, ésta podrá sufrir actualizaciones, mejoras y ampliaciones. Storytelling proporciona acceso a la Plataforma APP tal como está en cualquier día en particular sin obligaciones adicionales, excepto lo mencionado explícitamente en estos TOS. Storytelling se reserva el derecho de modificar, reemplazar, denegar el acceso, suspender o cesar partes o la totalidad de la Plataforma APP, exclusivamente para El creador de contenido o para todos CREADORES DE CONTENIDO y Usuarios, a su exclusivo criterio. Estas modificaciones entran en vigencia ya sea al momento de su publicación en la Plataforma APP o mediante comunicación directa con CREADOR DE CONTENIDO, a menos que se indique lo contrario.</p>
                    <p>5.2 Al operar como proveedor de servicios intermediario, Storytelling no está obligado a supervisar o controlar el Contenido. Sin embargo, Storytelling se reserva el derecho de realizar tales acciones. Storytelling también se reserva el derecho de rechazar, eliminar o descartar cualquier Contenido de la Plataforma APP o bloquear la cuenta DEL CONTENIDO ya sea parcial o totalmente, con o sin previo aviso, si se considera que viola estos TOS.</p>
                    <p>5.3 LA PLATAFORMA APP SE OFRECE “TAL CUAL” Y “SEGÚN DISPONIBILIDAD”, SIN NINGUNA GARANTÍA, YA SEA EXPRESA O IMPLÍCITA.  EL CREADOR DE CONTENIDO ACEPTA Asumir la ÚNICA RESPONSABILIDAD DE TODOS LOS RIESGOS Y REPERCUSIONES DERIVADOS DEL USO DE LA PLATAFORMA APP. ESTO INCLUYE, PERO NO ESTÁ RESTRINGIDO A, RIESGOS VINCULADOS A LA EXPOSICIÓN PÚBLICA EN LA PLATAFORMA APP, COMO GRABACIÓN, PIRATERÍA, ACOSO, DIFAMACIÓN, VIOLACIONES DE DERECHOS Y DISTRIBUCIÓN NO AUTORIZADA. Storytelling SE COMPROMETE A HACER ESFUERZOS RAZONABLES Y LEGALES PARA ELIMINAR DICHO CONTENIDO PREVIA SOLICITUD POR ESCRITO DEL CREADOR DE CONTENIDO.</p>
                    <p>5.4 Storytelling NO PUEDE GARANTIZAR EL ACCESO ININTERRUMPIDO O LIBRE DE ERRORES A LA PLATAFORMA APP SEGÚN LAS PREFERENCIAS D CREADOR DE CONTENIDO. Storytelling NO ES RESPONSABLE DE NINGUNA FALLA DERIVADA DE EVENTOS IMPREVISTOS O CIRCUNSTANCIAS FUERA DE SU CONTROL RAZONABLE. ESTO COMPRENDE FACTORES COMO CALAMIDADES NATURALES, FALLAS DE INFRAESTRUCTURA, PIRATERÍA, SPAM, MAL FUNCIONAMIENTO DEL SOFTWARE O DEL SERVIDOR, Y CAMBIOS DE PROVEEDOR DE SERVICIOS.</p>
                    <p>5.5 EN LA MEDIDA MÁS EXTENSA PERMITIDA POR LA LEY, Storytelling (Y SU PERSONAL ASOCIADO) NO TIENE NINGUNA RESPONSABILIDAD HACIA EL CREADOR DE CONTENIDO POR CUALQUIER DAÑO INDIRECTO, CONSECUENTE O SIMILAR, INCLUIDA LA PÉRDIDA DE INGRESOS, LA PÉRDIDA DE GANANCIAS O LA PÉRDIDA DE DATOS. SI ESTA SECCIÓN (5.5) SE INVALIDA, LA RESPONSABILIDAD TOTAL DE Storytelling POR CUALQUIER DAÑO, YA SEA CONTRACTUAL O EXTRACONTRACTUAL, TENDRÁ UN LÍMITE DE CIEN ($100,00) DÓLARES.</p>
                    <p>5.6 Indemnización. En la medida máxima permitida por la ley aplicable,  EL  CREADOR DE CONTENIDO acepta defender, indemnizar y eximir de responsabilidad a Storytelling , la Plataforma APP, su operador, matriz, subsidiarias y corporaciones afiliadas, incluidos sus funcionarios, directores, accionistas, empleados, agentes y equipos de mantenimiento del servidor. , contratistas independientes, proveedores de telecomunicaciones y contribuyentes contra cualquier reclamo, daño, obligación, pérdida, responsabilidad, costo, deuda y gasto (incluidos, entre otros, honorarios de abogados) que surjan de: (i) el uso y acceso DEL CONTENIDO” a la Plataforma APP; (ii) cualquier incumplimiento o violación de estos TOS por parte d creador de contenido ; (iii) la infracción por parte DEL CONTENIDO” de derechos de terceros, especialmente derechos de propiedad intelectual, propiedad o privacidad; o (iv) cualquier afirmación de que el Contenido perjudicó a un tercero. Esta obligación de indemnización persiste más allá de estos TOS y del uso de la Plataforma APP por parte d creador de contenido.</p>
                    <p>Además, UPLOADER se compromete a defender e indemnizar a Storytelling si cualquier tercero sufre debido a los actos ilegales de UPLOADER o si Storytelling tiene que abordar algún reclamo, incluidas, entre otras, cualquier acción criminal instigada por cualquier entidad. UPLOADER también acepta proteger a Storytelling de cualquier responsabilidad derivada de que otras personas vean el Contenido de UPLOADER. Storytelling informará al “CREADORES DE CONTENIDO” rápidamente sobre dichos reclamos o demandas y, a discreción de Storytelling, podrá optar por cooperar en la defensa de dichos reclamos a expensas d creador de contenido. Storytelling se reserva el derecho de intervenir en la defensa de cualquier reclamación, seleccionando a su abogado, aunque no está obligada a ello.</p>
                    <p>EL CREADOR DE CONTENIDO reconoce que las comunicaciones entre El creador de contenido y otros LOS CREADORES DE CONTENIDO o Usuarios en la Plataforma APP, incluidas las transmisiones de audio en vivo y los chats, no se consideran privadas.  EL CREADOR DE CONTENIDO libera a Storytelling y a todos los demás LOS CREADORES DE CONTENIDO o Usuarios de cualquier responsabilidad relacionada con la invasión de la privacidad, difamación o cuestiones relacionadas si las comunicaciones o los perfiles DEL CONTENIDO” se vuelven públicos. Esta sección no tiene como objetivo restringir el alcance de las exenciones o indemnizaciones establecidas en otras partes de estos TOS.</p>

                    <h3>6. DECLARACIONES Y GARANTÍAS DE LOS CREADORES DE CONTENIDO</h3>
                    <p>Declaraciones y Garantías:  Como condición esencial para que Storytelling acepte estos TOS, UPLOADER garantiza y declara:</p>
                    <p>6.1 CREADOR DE CONTENIDO, en su sano juicio y cuerpo, acepta estos TOS de forma voluntaria y comprende plenamente sus términos, condiciones e implicaciones legales.</p>
                    <p>6.2 EL CREADOR DE CONTENIDO TIENE AL MENOS 18 AÑOS Y HA ALCANZADO LA MAYORÍA DE EDAD Y EL CONSENTIMIENTO LEGAL en su jurisdicción al acceder a la Plataforma APP y establecer la cuenta Storytelling UPLOADER.</p>
                    <p>6.3 Integridad creador de contenido:  EL CREADOR DE CONTENIDO debe poseer o poseer derechos de licencia exclusivos sobre su Contenido para su carga, licencia/sublicencia o venta legal en la Plataforma APP. Esto debe realizarse sin infringir derechos de terceros, incluidos, entre otros, contratos, derechos de autor, marcas comerciales, derechos de publicidad o privacidad, o cualquier derecho relacionado. Está estrictamente prohibido subir contenido bajo licencias no exclusivas, incluso con derechos de monetización.</p>
                    <p>6.4 EL CREADOR DE CONTENIDO tiene y conservará la plena autoridad para cargar, licenciar y vender su Contenido como se detalla en estos TOS y cumplir con todas las demás obligaciones, garantías e indemnizaciones aquí establecidas. UPLOADER asume plena responsabilidad por los envíos de contenido.</p>
                    <p>6.5 El Contenido consta de materiales legales. Todas las personas representadas eran adultos (mayores de 18 años y la mayoría de edad en la jurisdicción d creador de contenido) durante la creación del Contenido. Estas personas brindaron su consentimiento válido y legal, están registradas con Storytelling, verificadas por Storytelling y le han otorgado a Storytelling todos los consentimientos, licencias, liberaciones, permisos y autorizaciones necesarios.</p>
                    <p>6.6 EL CREADOR DE CONTENIDO conoce, comprende y cumplirá todas las leyes y restricciones vigentes en su jurisdicción (y, de manera relevante, donde reside cualquier otro “CREADORES DE CONTENIDO” o Usuario). Esto se refiere a áreas como obscenidad, contenido dañino para menores, creación de audio contenido sexualmente explícito, requisitos de mantenimiento de registros, leyes de control de importación/exportación, publicidad engañosa, privacidad, antidiscriminación, propiedad intelectual, solicitud de negocios ilegales y restricciones de mensajes comerciales no solicitados.  UPLOADER no utilizará la Plataforma APP para solicitar o promover actividades ilegales o infracciones de los TOS.</p>
                    <p>6.7 EL CREADOR DE CONTENIDO es responsable de obtener y mantener todos los consentimientos, licencias y permisos necesarios para cargar, otorgar licencias y vender Contenido en la Plataforma APP. UPLOADER no realizará cargas desde lugares públicos o ubicaciones restringidas por las leyes locales.</p>
                    <p>6.8 Ciertos actos y presentaciones de contenido están estrictamente prohibidos: (i) sugerir que alguien tiene menos de 18 años (o menos de 21 en jurisdicciones específicas), (ii) representar a menores en situaciones explícitas, (iii) violar estos TOS, especialmente las Reglas de la Plataforma APP, y ( iv) hablar de marcas comerciales de terceros, derechos de autor o cualquier contenido despectivo.</p>
                    <p>6.9 Para transmisiones de audio  en vivo o interacciones con Usuarios,  El  creador de contenido tiene prohibido: (i) hacerse pasar por otros, (ii) solicitar dinero no autorizado a los Usuarios, (iii) acosar o difamar a otros, (iv) compartir Contenido contrario, (v) sugerir lugares públicos Cargas, (vi) eludir la Plataforma APP para sesiones en vivo o pagos, (vii) promover actividades ilegales, (viii) compartir información sobre Usuarios o LOS CREADORES DE CONTENIDO , (ix) interrumpir chats en vivo o (x) grabar audios transmisiones en vivo o chats.</p>
                    <p>6.10 EL CREADOR DE CONTENIDO garantiza que el Contenido cumpla con las Regulaciones 2257 (o reglas similares). UPLOADER conserva los documentos de verificación de edad y ha establecido un "Custodio de registros" como se define en las regulaciones.</p>
                    <p>6.11 EL CREADOR DE CONTENIDO se esforzará por cumplir con los pedidos de los Usuarios con prontitud y precisión.  EL CREADOR DE CONTENIDO puede rechazar pedidos. La falta de entrega podría dar lugar a reembolsos o deducciones de las ganancias del creador de contenido.</p>
                    <p>6.12 Mientras dure su cuenta de Storytelling y tres meses después del cierre, El creador de contenido no desviará el negocio de Storytelling, CREADORES DE CONTENIDO o los Usuarios a sitios web de la competencia.</p>
                    <p>6.13 EL CREADOR DE CONTENIDO no promocionará marcas que no sean de su propiedad en la Plataforma APP, excluyendo sus marcas personales. Se permiten promociones con marcas de agua para marcas propias.</p>
                    <p>6.14 EL CREADOR DE CONTENIDO ha revisado y cumplirá con la lista de artículos prohibidos que se encuentra en las políticas de Storytelling, garantizando que no haya promoción ni venta de estos artículos en la Plataforma APP.</p>
                    <p>6.15 EL CREADOR DE CONTENIDO o sus asociados no solicitarán datos personales de los Usuarios ni aceptarán reunirse en persona.</p>
                    <p>6.16 EL CREADOR DE CONTENIDO no tergiversará los servicios de Storytelling ni dará declaraciones engañosas sobre los servicios de la Plataforma APP de Storytelling o estos TOS.</p>
                    <p>6.17 EL CREADOR DE CONTENIDO cumplirá con todas las leyes, regulaciones y términos, pautas o políticas adicionales de Storytelling.</p>
                    <p>6.18 UPLOADER cumplirá con los requisitos de subtítulos de la Ley de Estadounidenses con Discapacidades (ADA). Si EL CREADOR DE CONTENIDO opta por no utilizar subtítulos, será el único que asumirá las responsabilidades legales derivadas de cualquier acción relacionada.</p>

                    <h3>7. PLAZO Y TERMINACIÓN</h3>
                    <p>7.1 Fecha de vigencia:  Estos TOS se activan tras el uso inicial de la Plataforma APP por parte DEL CONTENIDO” y continúan mientras El creador de contenido la utilice.  EL CREADOR DE CONTENIDO puede cancelar la cuenta cuando lo desee, a su entera discreción.</p>
                    <p>7.2 Derechos de terminación de Storytelling:  Storytelling puede finalizar estos TOS y la cuenta del CREADOR DE CONTENIDO” en cualquier momento, con o sin motivo. Si Storytelling cancela la cuenta del UPLOADER debido a una violación material o incumplimiento de estos TOS, el UPLOADER reconoce que cualquier monto pendiente que Storytelling adeude al UPLOADER se perderá.</p>
                    <p>7.3 Derechos posteriores a la terminación:  Independientemente de la terminación de los TOS, todos los derechos otorgados a Storytelling por UPLOADER durante su vigencia permanecen intactos.</p>

                    <h3>8. MARCAS COMERCIALES, MARCAS DE SERVICIOS, NOMBRES COMERCIALES, INFORMACIÓN COMERCIAL Y LOGOTIPOS</h3>
                    <p>Las "Marcas" (marcas comerciales, marcas de servicios, nombres comerciales, imágenes comerciales y logotipos) detalladas en la Plataforma APP, así como asociadas con cualquier producto disponible allí, pertenecen exclusivamente a Storytelling o sus licenciantes. No se pueden copiar, imitar ni utilizar, total o parcialmente, sin el consentimiento por escrito de Storytelling o sus licenciantes. Esto también se aplica a encabezados de página, gráficos personalizados, iconos de botones y scripts. Storytelling defenderá enérgicamente sus derechos de propiedad intelectual.</p>

                    <h3>9. INFORMACIÓN CONFIDENCIAL; NO ELUSIÓN</h3>
                    <p>9.1 Confidencialidad:  Durante la vigencia de estos TOS y diez (10) años después de su terminación, ambas partes deben mantener la confidencialidad de cualquier información de propiedad divulgada, independientemente de su forma. Los detalles de propiedad de Storytelling cubren, entre otros, aspectos como su Plataforma APP, software, empleados, clientes, afiliados, proveedores de servicios, activos intelectuales, características, diseños, datos y prácticas comerciales. Cualquier incumplimiento de esta confidencialidad por parte DEL CONTENIDO” de contenido incurrirá en una multa de cincuenta mil ($50,000.00) dólares por ocurrencia.</p>
                    <p>9.2 No elusión:  A lo largo de estos TOS y durante DOS (2) años después de su conclusión, EL CREADOR DE CONTENIDO se compromete a no eludir a Storytelling de ninguna manera para iniciar o intentar una relación comercial con los asociados, afiliados o sublicenciatarios de Storytelling que pueda poner en peligro a Storytelling. Cualquier contravención a este compromiso resultará en una multa de cincuenta mil ($50,000.00) dólares por incidente.</p>

                    <h3>10. ENLACES EXTERNOS</h3>
                    <p>10.1 Enlaces externos entrantes:  LOS CREADORES DE CONTENIDO, de conformidad con estos TOS, pueden vincularse a la Plataforma APP para mostrar su Contenido desde sitios o Plataforma App de terceros, siempre que estos sitios de terceros tengan TOS comparables y no apoyen ni anuncio a la prostitución.</p>
                    <p>10.2 Enlaces externos salientes: CREADORES DE CONTENIDO no deben agregar ni mencionar enlaces que salgan de la Plataforma APP en su Contenido, perfil o cualquier área relacionada, a menos que se permita explícitamente. Para aclarar, CREADORES DE CONTENIDO pueden vincularse a sitios de redes sociales autorizados o enlaces específicos en la Plataforma APP.</p>
                    <p>Si Storytelling detecta cualquier desviación de las estipulaciones de la Sección 10, Storytelling se reserva el derecho de cerrar rápidamente la cuenta d creador de contenido. Storytelling no es responsable del contenido, las políticas de privacidad o la recopilación de datos de sitios externos.</p>

                    <h3>11. Anuncios Storytelling (Esta función en un plan de activación de mediano plazo)</h3>
                    <p>11.1 Anuncios de Storytelling :  Storytelling a mediano plazo  ofrecerá  la opción al “CREADORES DE CONTENIDO” de comprar espacio publicitario en Storytelling  para promocionar el Contenido DEL CONTENIDO” y la visibilidad del perfil para los Usuarios en la popular página de Storytelling   aquí   (“Espacio publicitario”). UPLOADER reconoce que la cantidad precisa de vistas y tráfico, durante un Período publicitario comprado (como se define a continuación), puede variar según la cantidad total de anuncios comprados para ese período. La visibilidad de los anuncios se asignará de manera equitativa entre todos los anuncios comprados durante un período publicitario específico.</p>
                    <p>El espacio publicitario solo se utilizará para publicidad interna dentro de Storytelling. Al activar esta función en la App No podrá utilizarse para publicitar o promocionar artículos o servicios de terceros; esta exclusión incluye enlaces externos y cuentas de contenido en Sitios de terceros (como se define en la Sección 3.7).</p>
                    <p>11.2 Anuncios:  el espacio publicitario solo se utilizará para anunciar el Contenido ya subido al perfil creador de contenido, solo cuando esta función esté disponible.</p>
                    <p>11.3 Requisito de aprobación de anuncios:  todo el contenido publicitario potencial estará sujeto a selección y moderación y primero debe ser aprobado por el equipo de soporte de Storytelling. Este proceso de aprobación puede demorar hasta tres (3) días hábiles. Storytelling se reserva el derecho de rechazar anuncios que no cumplan con los TOS aquí establecidos o con las Reglas de la Plataforma APP que se encuentran  aquí . Los anuncios anunciados en Storytelling están sujetos a los mismos Términos de servicio aplicables al Contenido y deben cumplir con las Reglas de la Plataforma APP. Los anuncios que violen o infrinjan cualquiera de los TOS o las Reglas de la Plataforma APP que se aplican al Contenido enfrentarán las mismas consecuencias. (Esta función en un plan de activación de mediano plazo)</p>
                    <p>11.4 Período publicitario:   se reserva el derecho de rechazar solicitudes de espacio publicitario si Storytelling determina que UPLOADER ha comprado demasiados períodos publicitarios en un solo mes.</p>
                    <p>11.5 Transferencia: Si EL CREADOR DE CONTENIDO desea modificar el Contenido o el Período publicitario del Espacio publicitario, El creador de contenido debe comunicarse con el Equipo de soporte de Storytelling. No se permitirán cambios o nuevas aprobaciones dentro de los tres (3) días hábiles posteriores al Período publicitario y Storytelling se reserva el derecho de rechazar cualquier solicitud de transferencia. (Esta función en un plan de activación de mediano plazo)</p>
                    <p>11.6 Precios y pago:  Storytelling se reserva el derecho de ajustar los precios de los anuncios en función de diversos factores, incluido el tráfico previsto para un período publicitario específico. Se proporcionará una factura al “CREADORES DE CONTENIDO” antes del pago. Si hay alguna discrepancia entre el precio listado de la Plataforma APP Storytelling y el precio listado de la factura, el precio de la factura se mantendrá.
                        (Esta función en un plan de activación de mediano plazo)
                    </p>
                    <p>El espacio publicitario se puede adquirir mediante tarjeta de crédito. Si hay problemas con los pagos con tarjeta de crédito, Storytelling podría compensar la deuda con las ganancias del perfil de creador de contenido. Revertir los cargos en las compras puede dar lugar a restricciones en futuras adquisiciones u otros servicios Storytelling de UPLOADER, o incluso a la suspensión o cancelación de la cuenta. (Esta función en un plan de activación de mediano plazo) </p>
                    <p>Si el  creador de contenido ya no desea anunciarse durante un período específico y no desea transferir la compra, puede retirar su solicitud de espacio publicitario y solicitar un reembolso avisando con al menos siete (7) días calendario de antelación al equipo de soporte de Storytelling  al  soporte@Storytelling.app  . No se emitirán reembolsos por solicitudes realizadas menos de siete (7) días antes del Período publicitario comprado. Si EL CREADOR DE CONTENIDO infringe los términos, lo que lleva a la terminación de los derechos del espacio publicitario, no se realizarán reembolsos. (Esta función en un plan de activación de mediano plazo)</p>

                    <h3>12. VARIOS</h3>
                    <p>12.1 Avisos: (Esta función en un plan de activación de mediano plazo).</p>
                    <p>12.2 Jurisdicción y ley aplicable:  Estos TOS se regirán e interpretarán de acuerdo con las leyes de Florida en EEUU aplicables en la misma; Se excluye expresamente la aplicación de la Convención de las Naciones Unidas sobre Contratos de Compraventa Internacional de Mercaderías.  El creador de contenido y Storytelling acuerdan irrevocablemente que los tribunales de Florida en EEUU tendrán jurisdicción exclusiva para resolver cualquier disputa o reclamo que surja de o en conexión con estos TOS o su tema o formación (incluidas disputas o reclamos no contractuales).</p>
                    <p>12.3 Idioma: Si estos TOS se traducen a cualquier otro idioma, será solo para fines informativos y el idioma español prevalecerá en caso de cualquier conflicto entre la versión traducida y la versión en español.</p>
                    <p>12.4 Acuerdo completo: Estos TOS establecen el acuerdo completo entre El creador de contenido y Storytelling y reemplazan todos los demás acuerdos escritos u orales. Si alguna disposición de estos TOS se considera inválida según la ley aplicable, dicha disposición será ineficaz solo en la medida de dicha invalidez, sin invalidar las disposiciones restantes de estos TOS.</p>
                    <p>12.5 Sin cesión: estos TOS y cualquier derecho y licencia otorgados en virtud del presente no pueden ser transferidos ni cedidos por usted, pero sí podemos cederlos sin restricciones.</p>
                    <p>12.6 Sin otras limitaciones: Nada en este documento se interpretará como limitar o impedir la capacidad de contenido o de Storytelling para llevar a cabo sus respectivos negocios con respecto a asuntos que no se abordan específicamente en estos TOS.</p>
                    <p>12.7 Elección del idioma:  EL CREADOR DE CONTENIDO y Storytelling han elegido que estos TOS y cualquier documento relacionado se redacten en español.</p>
                    <p>12.8 Si EL CREADOR DE CONTENIDO tiene alguna pregunta o queja con respecto a estos TOS o la Plataforma APP, debe comunicarse con nosotros en  soporte@Storytelling.app .</p>
                </div>

                <div className="politicspromise__form__copyright">
                    <p>Correo electrónico: soporte@Storytelling.app</p>
                    <p>WEB: www.Storytelling.app</p>
                    <p>Dirección legal: 9274 sw 5th street, Boca Raton, FI, 33428</p>
                    <p>2024 Storytelling Inc. :: All rights reserved.</p>
                </div>
            </div>
            <GoToTop />
        </div>
    )
}

export default UploadTermsScreen;
import React, { useState } from "react";
import "./PoliticsScreen.css";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";
import arrowleft from "../assets/arrow-left.svg";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/storytelling-logo-white.png";

function ReimburseScreen() {

    const [activeIndex, setActiveIndex] = useState(null);

    const reimburseData = [
        {
            "id": 1,
            "question": '1. Reembolsos por Contenido no Descargado:',
            "answer": 'Las compras en Storytelling son definitivas y no reembolsables. No incluye las   suscripciones,  son reembolsables hasta 15 días después de la compra . Se ofrecen reembolsos completos dentro de los 7 días posteriores a la compra, exclusivamente si la compra del contenido no ha sido utilizada. La solicitud debe realizarse a través de nuestro servicio de atención al cliente en la página web o por correo electrónico. Si necesitas un reembolso para una compra que no se ha completado correctamente, es posible que se te pueda proporcionar un reembolso Para solicitar un reembolso, debes contactar con Storytelling  a través del formulario de contacto general que la red social pone a disposición de los usuarios. El tiempo de respuesta promedio es de 5 días hábiles  laborables.',
        },
        {
            "id": 2,
            "question": '2. Reembolsos por Contenido Descargado:',
            "answer": 'No se otorgan reembolsos por contenido descargado. Es responsabilidad del usuario asegurarse de que el contenido sea adecuado antes de la descarga o compra minutos con el creador de contenido.',
        },
        {
            "id": 3,
            "question": '3. Reembolsos por Contenido Defectuoso:',
            "answer": 'Se otorgan reembolsos completos en cualquier momento si el contenido descargado está defectuoso. Comuníquense con nuestro servicio de atención al cliente para procesar la solicitud.',
        },
        {
            "id": 4,
            "question": '4. Exclusiones:',
            "answer": 'No se otorgan reembolsos por contenido descargado o utilizado, contenido dañado o modificado por el usuario, o contenido adquirido a través de canales no autorizados.',
        },
        {
            "id": 5,
            "question": '5. Proceso de Reembolso:',
            "answer": 'Las solicitudes de reembolso se procesarán lo antes posible. Si la solicitud es aprobada, se reembolsará el monto total de la compra a través del mismo método de pago utilizado.',
        },
        {
            "id": 6,
            "question": '6. Garantía de Satisfacción del 100%:',
            "answer": 'La garantía de satisfacción del 100% no se aplica si el contenido ha sido descargado o utilizado, ha sido dañado o manipulado, o no ha sido utilizado según las instrucciones.',
        },
        {
            "id": 7,
            "question": '7. Políticas Específicas para la Industria para Adultos:',
            "answer": 'Storytelling cumple con todas las leyes y regulaciones aplicables en la industria para adultos. Las políticas son claras y fáciles de entender, considerando la sensibilidad de los clientes en esta industria.',
        },
        {
            "id": 8,
            "question": '8. Consideraciones Específicas para Storytelling:',
            "answer": 'Dado que ofrecemos una amplia variedad de contenido, las políticas diferencian entre contenido no descargado, contenido descargado y contenido defectuoso. Además, se reconoce la diferencia entre contenido descargable y en streaming.',
        }
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    return (
        <div>
            <div className='politicspromise'>
                <header className="politicspromise__banner"
                    style={{
                        backgroundSize: "cover",
                        backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, #8609a5, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover02.webp?alt=media&token=3760f7a5-3e9a-49d2-87e2-bfde1964e602&_gl=1*1c2i1cf*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc1Ni4yNi4wLjA."
                )`,
                        backgroundPosition: "top center",
                    }}>
                    <div className='politicspromise__content'>
                        <Link to="/" className="politicspromise__back" >
                            <img
                                className='politicspromise__back__img'
                                src={arrowleft}
                                alt=""
                            />
                            <p className='politicspromise__back__msg' >Regresar a la página de inicio</p>
                        </Link>

                        <div style={{ padding: "40px 0" }} >
                            <div>
                                <img
                                    src={logo}
                                    alt=''
                                    style={{ maxWidth: "250px" }}
                                />
                            </div>
                            <h1>Política de Reembolso</h1>

                            <img
                                className='politicspromise__back__img'
                                src={arrowdown}
                                alt=""
                            />
                        </div>
                    </div>
                </header>

                <p className='politicspromise__description'>Storytelling es una plataforma de audio contenido erótico que ofrece una amplia variedad de historias, novelas, audiolibros y podcasts para adultos. Estamos comprometidos a brindar a nuestros clientes una experiencia de compra satisfactoria y, por ello, ofrecemos políticas de reembolso claras y justas.</p>

                <div className='faq'>
                    {reimburseData.map((item, index) => (
                        <div className='faq__container' key={index}>
                            <div
                                className={`faq__item ${index === activeIndex ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <h3>{item.id}. {item.question}</h3>
                                <span>{index === activeIndex ? '-' : '+'}</span>
                            </div>
                            {index === activeIndex && <p className='faq__answer'>{item.answer}</p>}
                        </div>
                    ))}
                </div>

                <p className='politicspromise__description'>Estas políticas son claras, concisas y fáciles de entender. También cumplen con las leyes y regulaciones aplicables. Además, tienen en cuenta las consideraciones específicas de la industria para adultos y de Storytelling.</p>

                <div className="politicspromise__form__copyright">
                    <p>Correo electrónico: soporte@Storytelling.app</p>
                    <p>WEB: www.Storytelling.app</p>
                    <p>Dirección legal: 9274 sw 5th street, Boca Raton, FI, 33428</p>
                    <p>2024 Storytelling Inc. :: All rights reserved.</p>
                </div>
            </div>
            <GoToTop />
        </div>
    )
}

export default ReimburseScreen;
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { /* useSpring,  */animated } from 'react-spring';
import { Link } from "react-scroll";
import firebaseApp from "../firebase";
import "firebase/compat/firestore";
import { serverTimestamp } from "firebase/firestore";
import emailjs from "@emailjs/browser";
import loader from "../assets/abracadabra_loader.gif";
import logo from "../assets/storytelling-logo.png";
import logoblanco from "../assets/storytelling-logo-white.png";
/* import iconlogo from "../assets/abracadabra-icon-red.svg"; */
import appstore from "../assets/app-store.svg";
import googleplay from "../assets/google-play.svg";
import callcenter from "../assets/callcenter.svg";
import './HomeScreen.css';
import Typewriter from 'typewriter-effect';
import AudioPlayer from '../components/AudioPlayer';
import Footer from '../components/Footer';


const Welcome = () => {
  const navigate = useNavigate();
  const form = useRef();
  const db = firebaseApp.firestore();
  const [selectedAccount, setSelectedAccount] = useState("user");
  const [formStatus, setFormStatus] = useState(true);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmitAccess = async (e) => {
    e.preventDefault();

    if (firstname !== "" && lastname !== "" && email !== "") {
      await db.collection("account_data")
        .add({
          nombre: firstname,
          apellidos: lastname,
          email: email,
          account: selectedAccount,
          fecha: Date.now(),
          timestamp: serverTimestamp(),
          id: (email.substring(0, 3)) + Date.now(),
        })
        .then(() => {
          setFormStatus(false);
        })
        .catch((error) => {
          alert(error.message);
        });

      emailjs
        .sendForm(
          "service_w582xyd",
          "template_dv4rjsp",
          form.current,
          "yUw-dDL3bKa5NJ5kz"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      alert("Por favor ingrese datos en todas las casillas.")
    }
  };

  const handleChangeAccount = (e) => {
    setSelectedAccount(e.target.value)
  }

  const toClientService = () => {
    navigate('/servicioalcliente');
  };

  return (
    <div className="welcome" style={{
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, #8609a5 10%, transparent 90%),
           url(
          "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover01.webp?alt=media&token=8f205751-35cd-4595-8b18-1feb63731359&_gl=1*10t7lhu*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3MjcyMi42MC4wLjA."
      )`,
      backgroundPosition: "center center",
    }}>
      <div className="welcome__container">
      <div className="welcome__callcenter">
        {/* <img
          onClick={toClientService}
          src={callcenter}
          alt='Servicio al cliente'
          title='Servicio al cliente'
        /> */}
      </div>
      <div className="welcome__img">
        <img
          src={logoblanco}
          alt=''
        />
      </div>

      <h1>¡Tus historias cobran vida!</h1>
      {/* <div className="welcome__store">
        <img
          src={appstore}
          alt=''
          style={{ width:"150px" }}
        />
        <img
          src={googleplay}
          alt=''
          style={{ width:"150px" }}
        />
      </div> */}

      <p>"Descubre un tesoro escondido de historias cautivadoras, inspiradoras y transformadoras que te transportarán a nuevos mundos, te inspirarán a perseguir tus sueños y te harán llorar."</p>

      <Link to="slider" smooth={true} duration={500} >
        <div className="welcome__scrolldown">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 813.3 813"
            xmlSpace="preserve"
          >
            <path
              fill="white"
              d="M407 6C186.1 6 7 185 7 406s179.1 400 400 400 400-179 400-400S627.9 6 407 6zm0 750C213.7 756 57 599.2 57 406S213.7 56 407 56s350 156.8 350 350-156.7 350-350 350z"
            ></path>
            <path
              fill="white"
              d="M535 392.5L432 495.7V231c0-13.8-11.2-25-25-25s-25 11.2-25 25v264.7L279 392.5c-9.8-9.8-25.6-9.8-35.4 0-9.8 10-9.8 25.8 0 35.5L385 569.5c6 6 14.2 7.7 22 6.3 7.8 1.5 16-.3 22-6.3L570.4 428c9.8-9.8 9.8-25.5 0-35.5-9.7-9.8-25.6-9.8-35.4 0z"
            ></path>
          </svg>
        </div>
      </Link>

      </div>

    </div>
  );
};

const MainContent = () => {
  /* const navigate = useNavigate(); */
  const [scrollProgress, setScrollProgress] = useState(0);
  const [mobileScreenIndex, setMobileScreenIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScroll = documentHeight - windowHeight;
      const progress = (scrollY / maxScroll) * 100;
      setScrollProgress(progress);

      const newIndex = Math.min(
        Math.floor((progress / 100) * mobileScreenImages.length), // Updated to use 'progress'
        mobileScreenImages.length - 1
      );
      setMobileScreenIndex(newIndex);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Calculate newIndex and update textIndex here based on scrollProgress
    const newIndex = Math.min(
      Math.floor((scrollProgress / 100) * mobileScreenImages.length),
      mobileScreenImages.length - 1
    );
    setTextIndex(newIndex);
    /* setBackgroundLoaded(false); */
  }, [scrollProgress]);

  const backgroundImages = [
    'https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-back1-degradetotal.webp?alt=media&token=5480a9a6-668b-46d2-9504-04b324400e53',
    'https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-back2-degradetotal.webp?alt=media&token=ed9f1c03-2edd-4481-b8e5-67c5a563a107',
    'https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-back3-degradetotal.webp?alt=media&token=cdf37e79-6e20-46ad-a13e-0e27f9737c41',
  ];

  const messages = [
    'Ya seas emprendedor, un viajero, o simplemente alguien con una experiencia única para compartir, tu voz tiene el poder de cautivar, inspirar y transformar.',
    'Haz parte de esta nueva comunidad vibrante de narradores y oyentes que comparten tus historias, exploran las de otros y juntos construyen un mundo donde todos encuentran una voz.',
    'La historia tiene un lugar especial. Descarga Storytelling y comienza a compartir tus historias, a explorar la de otros y a conectarte con personas de ideas afines en todo el mundo.',
  ];

  const messages_mobile = [
    'Ya seas emprendedor, un viajero, o simplemente alguien con una experiencia única para compartir.',
    'Haz parte de esta nueva comunidad vibrante de narradores y oyentes que comparten tus historias.',
    'La historia tiene un lugar especial. Descarga Storytelling y comienza a compartir tus historias.',
  ];

  const mobileScreenImages = [
    'https://firebasestorage.googleapis.com/v0/b/cronis-web.appspot.com/o/resources%2FmobileScreens%2Fstorytelling-screenMobile1.png?alt=media&token=c4fcce61-9114-48b9-a94b-5fe92441dc9f',
    'https://firebasestorage.googleapis.com/v0/b/cronis-web.appspot.com/o/resources%2FmobileScreens%2Fstorytelling-screenMobile2.png?alt=media&token=97da691f-26b9-4068-9dc0-257fe26aba88',
    'https://firebasestorage.googleapis.com/v0/b/cronis-web.appspot.com/o/resources%2FmobileScreens%2Fstorytelling-screenMobile3.png?alt=media&token=1942fbe5-e72b-4f76-b3c9-6bae2fc02369',
  ];

  const backgroundIndex = Math.min(
    Math.floor((scrollProgress / 100) * backgroundImages.length),
    backgroundImages.length - 1
  );

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImages[backgroundIndex];
    img.onload = () => {
      setBackgroundLoaded(true);
    };
  }, [backgroundIndex]);

  /* const toClientService = () => {
    navigate('/servicioalcliente');
  }; */

  return (
    <div>
      {backgroundLoaded
        ? (
          <div className="main-content" id="slider">
            <animated.div className="background-scene" style={{ backgroundImage: `url(${backgroundImages[backgroundIndex]})`, backgroundSize: '100% auto', backgroundPosition: 'center', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>

            {/* <div className="welcome__callcenter">
              <img
                onClick={toClientService}
                src={callcenter}
                alt='Servicio al cliente'
                title='Servicio al cliente'
              />
            </div> */}

              <div className="main-content__nav" >
                <div className='main-content__nav__logo' >
                  <img
                    src={logo}
                    alt=''
                  />
                </div>
                {/* <div className='main-content__nav__storeicons' >
          <img
            src={appstore}
            alt=''
          />
          <img
            src={googleplay}
            alt=''
          />
        </div> */}
              </div>

              <div className='typewriter' >
                <div className="typewriter-container">
                  <div className="typewriter-text">
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriter
                          .typeString(messages[textIndex])
                          .callFunction(() => {
                          })
                          .pauseFor(2500)
                          .deleteAll()
                          .callFunction(() => {
                          })
                          .start();
                      }}
                      options={{
                        strings: [messages[textIndex]],
                        autoStart: true,
                        deleteSpeed: 0,
                        loop: true,
                        delay: 50,
                        speed: 5,
                      }}
                    />
                  </div>

                  <div className="typewriter-text__mobile">
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriter
                          .typeString(messages_mobile[textIndex])
                          .callFunction(() => {
                          })
                          .pauseFor(2500)
                          .deleteAll()
                          .callFunction(() => {
                          })
                          .start();
                      }}
                      options={{
                        strings: [messages_mobile[textIndex]],
                        autoStart: true,
                        deleteSpeed: 0,
                        loop: true,
                        delay: 50,
                        speed: 5,
                      }}
                    />
                  </div>
                </div>
                <div>
                  <animated.div className="phone-overlay">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2FiPhone_X_hand.png?alt=media&token=7a1d7891-fbc8-43ec-86c5-3ae740b141d9"
                      alt="Mobile Phone"
                    />
                    <animated.div className="mobile-screen" style={{
                      backgroundImage: `url(${mobileScreenImages[mobileScreenIndex]})`
                    }} />
                  </animated.div>
                </div>
              </div>

              {/* <Link to="additional" smooth={true} duration={500} >
        <div className="typewriter-text__headphone">
          <img
            src={headphone}
            alt=''
          />
          <p>Audios de muestra</p>
        </div>
      </Link> */}

            </animated.div>
          </div>
        )
        : (
          <div className="loading-indicator" style={{ width: "100%", height: "auto", justifyContent: "center", alignItems: "center", display: "flex", margin: "0" }} >
            <img
              src={loader}
              alt="Cargando..."
              title='Cargando...'
              style={{ borderRadius: "50%", width: "250px", height: "250px", objectFit: "cover" }}
            />
          </div>
        )
      }
    </div>
  );

};

const AdditionalContent = () => {
  const navigate = useNavigate();

  const toCreator = () => {
    navigate('/creator');
  };

  const toFaq = () => {
    navigate('/faq');
  };

  return (
    <div>
    <div className='additional' id="additional">

      <AudioPlayer />

      <div style={{ flex: 1, width: "100%" }} >
        <button className='additional__button' onClick={toFaq}>Preguntas Frecuentes</button>
      </div>
      <div style={{ flex: 1, width: "100%" }}>
        <button className='additional__button' onClick={toCreator}>Creación de contenido</button>
      </div>

      <div className='main-content__nav__storeicons' >
        {/* <img
          src={appstore}
          alt=''
        /> */}
        <img
          src={googleplay}
          alt=''
        />
      </div>
    </div>
      <Footer/>
    </div>
  );
};

const HomeScreen = () => {

  const backgroundImages = [
    'https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-back1-degradetotal.webp?alt=media&token=5480a9a6-668b-46d2-9504-04b324400e53',
    'https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-back2-degradetotal.webp?alt=media&token=ed9f1c03-2edd-4481-b8e5-67c5a563a107',
    'https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-back3-degradetotal.webp?alt=media&token=cdf37e79-6e20-46ad-a13e-0e27f9737c41',
  ];

  const [showAdditionalContent, setShowAdditionalContent] = useState(false);

  useEffect(() => {
    const background3Position = (2 / backgroundImages.length) * 100;

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScroll = documentHeight - windowHeight;
      const progress = (scrollY / maxScroll) * 100;

      const backgroundAttachment = progress >= background3Position ? 'scroll' : 'fixed';
      backgroundImages.forEach((bgImage, index) => {
        const backgroundElement = document.getElementById(`background-${index + 1}`);
        if (backgroundElement) {
          backgroundElement.style.backgroundAttachment = backgroundAttachment;
        }
      });

      setShowAdditionalContent(progress >= background3Position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="homescreen">
      <Welcome />
      <MainContent />
      {showAdditionalContent && <AdditionalContent />}
    </div>
  );
};

export default HomeScreen;
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import HomeScreen from './screens/HomeScreen';
import CreatorScreen from './screens/CreatorScreen';
import ClientServiceScreen from './screens/ClientServiceScreen';
import FaqScreen from './screens/FaqScreen';
import PriceScreen from './screens/PriceScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import ReimburseScreen from './screens/ReimburseScreen';
import UploadTermsScreen from './screens/UploadTermsScreen';
import TermsScreen from './screens/TermsScreen';

function App() {
  return (
    <div className="app">
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      {
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/creator" element={<CreatorScreen />} />
          <Route exact path="/faq" element={<FaqScreen />} />
          <Route exact path="/servicioalcliente" element={<ClientServiceScreen />} />
          <Route exact path="/price" element={<PriceScreen />} />
          <Route exact path="/privacy" element={<PrivacyScreen />} />
          <Route exact path="/reimburse" element={<ReimburseScreen />} />
          <Route exact path="/uploadterms" element={<UploadTermsScreen />} />
          <Route exact path="/terms" element={<TermsScreen />} />
        </Routes>
      }
      </Router>
    </div>
  );
}

export default App;
import React from 'react';
import { useNavigate } from "react-router-dom";
import "./Footer.css";

function Footer() {
    const navigate = useNavigate();

    const toPrice = () => {
        navigate('/price');
    };

    const toPrivacy = () => {
        navigate('/privacy');
    };

    const toReimburse = () => {
        navigate('/reimburse');
    };

    const toUploadterms = () => {
        navigate('/uploadterms');
    };

    const toTerms = () => {
        navigate('/terms');
    };

    return (
        <div className='footer'>
            <div className="footer__links">
                <p onClick={toPrice} >Política de Precios</p>
                <p onClick={toPrivacy} >Política de Privacidad</p>
                <p onClick={toReimburse} >Política de Reembolso</p>
                <p onClick={toUploadterms} >Política de Carga de Audios</p>
                <p onClick={toTerms} >Términos y condiciones</p>
            </div>

            <div className="footer__details">
                <p>Correo electrónico: soporte@Storytelling.app</p>
                <p>WEB: www.Storytelling.app</p>
                <p>Dirección legal: 9274 sw 5th street, Boca Raton, FI, 33428</p>
                <p>2024 Storytelling Inc. :: All rights reserved.</p>
            </div>

        </div>
    );
}

export default Footer;
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./ClientServiceScreen.css";
import  firebaseApp from "../firebase";
import "firebase/compat/firestore";
import { serverTimestamp } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import GoToTop from "../GoToTop";
import arrowleft from "../assets/arrow-left.svg";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/storytelling-logo-white.png";

function ClientServiceScreen() {
    const navigate = useNavigate();
    const form = useRef();
    const db = firebaseApp.firestore();
    const [formStatus, setFormStatus] = useState(true);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [requestType, setRequestType] = useState("No indica problema");
    const [clientcod, setClientcod] = useState("");
    const [obs, setObs] = useState("");

    const handleSubmitAccess = async (e) => {
        e.preventDefault();

        if (firstname !== "" && lastname !== "" && email !== "" && clientcod !== "") {
        await db.collection("client_service_data")
          .add({
            nombre: firstname,
            apellidos: lastname,
            email: email,
            requestType:requestType,
            clientcod:clientcod,
            fecha: Date.now(),
            timestamp: serverTimestamp(),
            id: (email.substring(0, 3))+Date.now(),
          })
          .then(() => {
            setFormStatus(false);
          })
          .catch((error) => {
            alert(error.message);
          });
        
        emailjs
        .sendForm(
            "service_w582xyd",
            "template_5j52t78",
            form.current,
            "yUw-dDL3bKa5NJ5kz"
        )
        .then(
            (result) => {
            console.log(result.text);
            console.log("message sent");
            },
            (error) => {
            console.log(error.text);
            }
        );
        } else {
            alert("Por favor ingrese datos en todas las casillas.")
        }
    };

    const handleRequestType = (e) => {
        setRequestType(e.target.value)
      }

    const toPrice = () => {
    navigate('/price');
    };

    const toPrivacy = () => {
        navigate('/privacy');
    };

    const toReimburse = () => {
        navigate('/reimburse');
    };

    const toUploadterms = () => {
        navigate('/uploadterms');
    };

    const toTerms = () => {
        navigate('/terms');
    };

    return (
        <div>
            <div className='clientservice'>
            <header className="clientservice__banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, purple, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-people-cover.webp?alt=media&token=12c45b04-ab95-414f-b748-ea65421fa476"
                )`, 
                backgroundPosition: "center center",
            }}>
            <div className='clientservice__content'>
                <Link to="/" className="clientservice__back" >
                    <img 
                    className='clientservice__back__img'
                    src={arrowleft}
                    alt=""
                    />
                    <p className='clientservice__back__msg' >Regresar a la página de inicio</p>
                </Link>

                <div className="clientservice__banner__content" >
                    <div className="clientservice__banner__content__logo">
                        <img
                        src={logo}
                        alt=''
                        />
                    </div>
                    <h1>Estamos aquí para ayudarte.</h1>
                    <img 
                        className='clientservice__back__img'
                        src={arrowdown}
                        alt=""
                    />
                </div>
            </div>
            </header>

            {formStatus &&
            <div className="clientservice__body" >

            <div className="clientservice__body__closure" >
                <p>Por favor ingresa tus datos y cuéntanos qué podemos hacer por ti.</p>

                <form className="clientservice__form__info" ref={form} onSubmit={handleSubmitAccess}>
                <ul>
                    <li>
                    <input type="text" name="firstname" placeholder='Nombre' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                    </li>
      
                    <li>
                    <input type="text" name="lastname" placeholder='Apellido' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </li>
      
                    <li>
                    <input type="email" name="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </li>

                    <div className="clientservice__form__info__select">
                        <p>¿Qué tipo de ayuda necesitas?</p>
                        <select value={requestType} onChange={handleRequestType}>
                            <option value="Problemas con la facturación">Problemas con la facturación
                            </option>
                            <option value="Problemas técnicos">Problemas técnicos</option>
                            <option value="Cuentas comprometidas o seguridad">Cuentas comprometidas o seguridad</option>
                            <option value="Devolución de compra o cambio de plan">Devolución de compra o cambio de plan</option>
                            <option value="Problemas con la calidad del servicio">Problemas con la calidad del servicio</option>
                            <option value="Asistencia y orientación general">Asistencia y orientación general</option>
                            <option value="Feedback y sugerencias">Feedback y sugerencias</option>
                            <option value="Problemas de contenido">Problemas de contenido</option>
                            <option value="Políticas y términos de servicio">Políticas y términos de servicio</option>
                            <option value="Asistencia con dispositivos y compatibilidad">Asistencia con dispositivos y compatibilidad</option>
                        </select>
                    </div>

                    <div className="clientservice__form__info__textarea">
                        <textarea rows="4" cols="50" type="text" name="obs" placeholder='Explícanos más...' value={obs} onChange={(e) => setObs(e.target.value)}>Explícanos más...</textarea>
                    </div>

                    <li>
                    <input type="text" name="clientcod" placeholder='Código de cliente' value={clientcod} onChange={(e) => setClientcod(e.target.value)} />
                    </li>

                    <input className="clientservice__form__info__submit" type="submit" value="Enviar" />
      
                </ul>
                </form>

            </div>
            </div>
            }

            {!formStatus &&
                <div className="clientservice__form__sent">
                  <h2>¡Genial! Hemos recibido tus datos y pronto te notificaremos los siguientes pasos.</h2>
                </div>
            }

            </div>

            <div className="clientservice__politics">
                <p onClick={toPrice} >Política de Precios</p>
                <p onClick={toPrivacy} >Política de Privacidad</p>
                <p onClick={toReimburse} >Política de Reembolso</p>
                <p onClick={toUploadterms} >Política de Carga de Audios</p>
                <p onClick={toTerms} >Términos y condiciones</p>
            </div>

            <div className="politicspromise__form__copyright">
                <p>Correo electrónico: soporte@Storytelling.app</p>
                <p>WEB: www.Storytelling.app</p>
                <p>Dirección legal: 9274 sw 5th street, Boca Raton, FI, 33428</p>
                <p>2024 Storytelling Inc. :: All rights reserved.</p>
            </div>

        <GoToTop/>
        </div>
    )
}

export default ClientServiceScreen;
import React, { useState } from "react";
import "./PoliticsScreen.css";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";
import arrowleft from "../assets/arrow-left.svg";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/storytelling-logo-white.png";

function PriceScreen() {

    const [activeIndex, setActiveIndex] = useState(null);

    const priceData = [
        {
            "id": 1,
            "question": 'Suscripción Gratuita:',
            "answer": 'La suscripción inicial a Storytelling es gratuita. Los usuarios pueden registrarse sin costo y disfrutar de un período de prueba para experimentar la plataforma.',
        },
        {
            "id": 2,
            "question": 'Suscripción Premium (Opcional):',
            "answer": 'A futuro, se considerará la opción de una suscripción premium que ofrezca beneficios adicionales, tales como descuentos exclusivos o características especiales. Esta suscripción tendrá un costo adicional de $3 USD.',
        },
        {
            "id": 3,
            "question": 'Promociones y Descuentos:',
            "answer": 'Storytelling puede ofrecer promociones y descuentos en paquetes de minutos de audio en ocasiones especiales o eventos.',
        },
        {
            "id": 4,
            "question": 'Facturación Segura:',
            "answer": 'Storytelling utiliza un sistema de facturación seguro para garantizar la privacidad y la seguridad financiera de sus usuarios.',
        }
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    return (
        <div>
            <div className='politicspromise'>
                <header className="politicspromise__banner"
                    style={{
                        backgroundSize: "cover",
                        backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, #8609a5, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover02.webp?alt=media&token=3760f7a5-3e9a-49d2-87e2-bfde1964e602&_gl=1*1c2i1cf*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc1Ni4yNi4wLjA."
                )`,
                        backgroundPosition: "top center",
                    }}>
                    <div className='politicspromise__content'>
                        <Link to="/" className="politicspromise__back" >
                            <img
                                className='politicspromise__back__img'
                                src={arrowleft}
                                alt=""
                            />
                            <p className='politicspromise__back__msg' >Regresar a la página de inicio</p>
                        </Link>

                        <div style={{ padding: "40px 0" }} >
                            <div>
                                <img
                                    src={logo}
                                    alt=''
                                    style={{ maxWidth: "250px" }}
                                />
                            </div>
                            <h1>Política de Precios</h1>
                            <img
                                className='politicspromise__back__img'
                                src={arrowdown}
                                alt=""
                            />
                        </div>
                    </div>
                </header>

                <div className='faq'>
                    {priceData.map((item, index) => (
                        <div className='faq__container' key={index}>
                            <div
                                className={`faq__item ${index === activeIndex ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <h3>{item.id}. {item.question}</h3>
                                <span>{index === activeIndex ? '-' : '+'}</span>
                            </div>
                            {index === activeIndex && <p className='faq__answer'>{item.answer}</p>}
                        </div>
                    ))}
                </div>

                <div className="politicspromise__form__copyright">
                    <p>Correo electrónico: soporte@Storytelling.app</p>
                    <p>WEB: www.Storytelling.app</p>
                    <p>Dirección legal: 9274 sw 5th street, Boca Raton, FI, 33428</p>
                    <p>2024 Storytelling Inc. :: All rights reserved.</p>
                </div>
            </div>
            <GoToTop />
        </div>
    )
}

export default PriceScreen;
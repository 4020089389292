import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./CreatorScreen.css";
import  firebaseApp from "../firebase";
import "firebase/compat/firestore";
import { serverTimestamp } from "firebase/firestore";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";
import arrowleft from "../assets/arrow-left.svg";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/storytelling-logo-white.png";

function CreatorScreen() {
    const form = useRef();
    const db = firebaseApp.firestore();
    const [formStatus, setFormStatus] = useState(true);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");

    const handleSubmitAccess = async (e) => {
        e.preventDefault();
        await db.collection("creator_data")
          .add({
            nombre: firstname,
            apellidos: lastname,
            email: email,
            account:"creator",
            fecha: Date.now(),
            timestamp: serverTimestamp(),
            id: (email.substring(0, 3))+Date.now(),
          })
          .then(() => {
            setFormStatus(false);
          })
          .catch((error) => {
            alert(error.message);
          });
        
        emailjs
        .sendForm(
            "service_w582xyd",
            "template_5j52t78",
            form.current,
            "yUw-dDL3bKa5NJ5kz"
        )
        .then(
            (result) => {
            console.log(result.text);
            console.log("message sent");
            },
            (error) => {
            console.log(error.text);
            }
        );
    };

    return (
        <div>
            <div className='creatorpromise'>
            <header className="creatorpromise__banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, #8609a5, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover03.webp?alt=media&token=cbfd2cc8-e9a9-4e59-9dcb-6dc8a85ec0de&_gl=1*1lw88vo*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc3OC40LjAuMA.."
                )`, 
                backgroundPosition: "center center",
            }}>
            <div className='creatorpromise__content'>
                <Link to="/" className="creatorpromise__back" >
                    <img 
                    className='creatorpromise__back__img'
                    src={arrowleft}
                    alt="to demoqrata helpdesk"
                    />
                    <p className='creatorpromise__back__msg' href="/" >Regresar a la página de inicio</p>
                </Link>

                <div className="creatorpromise__banner__content" >
                    <div className="creatorpromise__banner__content__logo">
                        <img
                        src={logo}
                        alt=''
                        />
                    </div>
                    <h1>¡Bienvenido a Storytelling, donde las historias cobran vida!</h1>
                    <img 
                        className='creatorpromise__back__img'
                        src={arrowdown}
                        alt=""
                    />
                </div>
            </div>
            </header>

            {formStatus &&
            <div className="creatorpromise__body" >
            <div className="creatorpromise__body__definition" >
                <h2>"Storytelling es una plataforma que conecta a narradores y oyentes apasionados, ofreciendo un espacio para compartir historias que inspiran, motivan y transforman."</h2>
                <h3>Los usuarios pueden compartir sus experiencias, reflexiones y aprendizajes, conectándose con una comunidad vibrante de personas de todas partes.</h3>
            </div>

            <div className="creatorpromise__body__offer" >
            <div>
                <h3>Un universo de experiencias a tu alcance</h3>
                <p>¿Alguna vez has escuchado una historia que te ha transportado a otro mundo, te ha inspirado a perseguir tus sueños o te ha conmovido hasta las lágrimas? En Storytelling, encontrarás un tesoro inigualable de historias que tienen el poder de cautivar.</p>
            </div>
            <div  className='creatorpromise__body__offer__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fcreator_ref%2F2.webp?alt=media&token=4a4357f0-0fc5-4128-9240-60dcf319af98' alt="Tu Voz, Tu Poder" />
            </div>
            </div>

            <div className="creatorpromise__body__followers" >
            <div  className='creatorpromise__body__followers__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fcreator_ref%2F8.webp?alt=media&token=d6b154dd-ef8a-4bd6-8fdf-93ddedbf3ec1' alt="Monetiza Tu Seducción" />
            </div>
            <div>
                <h3>Más que solo palabras</h3>
                <p>En Storytelling, cada historia es una joya invaluable. No importa si eres un emprendedor apasionado, un viajero intrépido o simplemente alguien con una experiencia única que contar. Tu voz tiene el poder de cautivar, emocionar y transformar.</p>
            </div>
            </div>

            <div className="creatorpromise__body__together" >
            <div>
                <h3>Un escenario para todos</h3>
                <p>Olvídate de las plataformas donde solo las estrellas brillan. En Storytelling, eres el centro del universo. Comparte tus anécdotas, reflexiones, aprendizajes... ¡Deja que tu voz recorra el mundo!</p>
            </div>
            <div  className='creatorpromise__body__together__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fcreator_ref%2F14.webp?alt=media&token=0c6c2f57-ca77-4147-96ea-41c753d18d04' alt='Conecta y Excita' />
            </div>
            </div>

            <div className="creatorpromise__body__expectation" >
            <div  className='creatorpromise__body__expectation__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fcreator_ref%2F10.webp?alt=media&token=e2de48bc-fd9e-4791-aff8-36e69cb0e571' alt="Éxito Sensual" />
            </div>
            <div>
                <h3>Descubre un mundo de posibilidades</h3>
                <p>Sumérgete en un mar de historias que te inspirarán, motivarán y conectarán con personas de todas partes. Emprendimiento, viajes, logros académicos, experiencias de vida... En Storytelling encontrarás un sinfín de relatos que te enriquecerán y te harán vibrar.</p>
            </div>
            </div>

            <div className="creatorpromise__body__closure" >
                <h4>Únete a la comunidad</h4>
                <p>Descarga Storytelling y forma parte de una comunidad vibrante de narradores y oyentes apasionados. Comparte tu voz, explora las de otros y juntos construyamos un mundo donde cada historia tiene un lugar especial.</p>

                <form className="creatorpromise__form__info" ref={form} onSubmit={handleSubmitAccess}>
                <ul>
                    <li>
                    <input type="text" name="firstname" placeholder='Nombre' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                    </li>
      
                    <li>
                    <input type="text" name="lastname" placeholder='Apellido' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </li>
      
                    <li>
                    <input type="email" name="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </li>

                    <input className="creatorpromise__form__info__submit" type="submit" value="Enviar" />
      
                </ul>
                </form>

                {/* <div className="creatorpromise__form__info__paybutton">
                    <button 
                        ref={form}
                        type='submit' 
                        onClick={handleSubmitAccess} >Enviar
                    </button>
                </div> */}

                <div className="creatorpromise__form__copyright">
                    <p>2024 Storytelling Inc. :: All rights reserved.</p>
                </div>

            </div>
            </div>
            }

            {!formStatus &&
                <div className="creatorpromise__form__sent">
                  <h2>¡Genial! Hemos recibido tus datos y pronto te notificaremos los siguientes pasos.</h2>

                  <div className="creatorpromise__form__copyright">
                    <p>2024 Storytelling Inc. :: All rights reserved.</p>
                  </div>
                </div>
            }

            </div>
        <GoToTop/>
        </div>
    )
}

export default CreatorScreen;